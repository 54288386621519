import React from 'react';

import {
  Page,
  View,
  Document,
  StyleSheet,
  Text,
  Image,
  Font,
} from '@react-pdf/renderer';
import moment from 'moment';

import DMSerifDisplay from '../../assets/fonts/DMSerifDisplay-Regular.ttf';
import ParisienneRegular from '../../assets/fonts/Parisienne-Regular.ttf';
import CertificateBadge from '../../assets/images/certificate-badge.png';
import iCorpImg from '../../assets/images/i-corp.png';
import { capitalizeFirstLetter } from '../../utils/commonFunctions';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  header: {
    height: '18%',
    width: '100%',
    backgroundColor: '#3C4764',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '20px',
  },
  footer: {
    height: '8%',
    width: '100%',
    backgroundColor: '#3C4764',
  },
  headerTitle: {
    fontSize: '28px',
    color: 'white',
    fontWeight: 'extrabold',
    textTransform: 'uppercase',
    textAlign: 'right',
    width: '100%',
  },
  contentContainer: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainer: {
    display: 'flex',
    flex: 1,
    // backgroundColor: '#F0F0F0',
  },
  wrapper: {
    margin: '3px',
    borderWidth: 3,
    borderColor: 'black',
    flex: 1,
  },
  certificateBadge: {
    height: '200px',
    width: '200px',
    position: 'absolute',
    left: '5%',
    top: '35%',
  },
  smallText: {
    fontSize: '20px',
    color: '#3C4764',
  },
  userNameText: {
    fontSize: '52px',
    color: 'black',
    fontWeight: 'normal',
    marginTop: '20px',
    marginBottom: '20px',
    fontFamily: 'DMSerifDisplay',
    textTransform: 'capitalize',
  },
  smallBoldText: {
    fontSize: '16px',
    fontWeight: 1000,
    color: '#3C4764',
  },
  courseNameText: {
    fontSize: '20px',
    fontWeight: 'ultrabold',
    color: 'black',
    marginTop: '5px',
    marginBottom: '5px',
    fontFamily: 'DMSerifDisplay',
  },
  dateTitleText: {
    fontSize: '16px',
    // fontWeight: 1000,
    color: '#3C4764',
  },
  logoImage: {
    height: '55px',
    position: 'absolute',
    bottom: '15px',
    right: '15px',
  },
  dateValueText: {
    color: 'black',
    fontWeight: 'ultrabold',
  },
});

Font.register({
  family: 'ParisienneRegular',
  fonts: [
    {
      src: ParisienneRegular,
    },
  ],
});
Font.register({
  family: 'DMSerifDisplay',
  fonts: [
    {
      src: DMSerifDisplay,
    },
  ],
});

export const GenerateCertificatePdf = ({
  userName,
  courseName,
  courseCompletionDate,
}: any) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.mainContainer}>
          <View style={styles.wrapper}>
            <View style={styles.header}>
              <Text style={styles.headerTitle}>CERTIFICATE OF COMPLETION</Text>
              <Image
                src={CertificateBadge}
                style={styles.certificateBadge}
              ></Image>
            </View>
            <View style={styles.contentContainer}>
              <Text style={styles.smallText}>Awarded to:</Text>
              <Text style={styles.userNameText}>
                {capitalizeFirstLetter(userName)}
              </Text>
              <Text style={styles.smallBoldText}>
                For the successful completion of
              </Text>
              <Text style={styles.courseNameText}>{courseName}</Text>
              <Text style={styles.dateTitleText}>
                Date:{' '}
                <Text style={styles.dateValueText}>
                  {moment(courseCompletionDate).format('DD/MM/YYYY')}
                </Text>
              </Text>
              <Image src={iCorpImg} style={styles.logoImage}></Image>
            </View>
            <View style={styles.footer}></View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
