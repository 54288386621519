import * as React from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

function a11yProps(index: number) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CustomTabs({ tabs }: any) {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Box display={'flex'}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          sx={{
            'width': 'fitContent',
            '& .MuiButtonBase-root.MuiTab-root': {
              background: 'white',
              borderRadius: '4px 4px 0 0',
              borderBottom: `1px solid ${theme.palette.grey[200]}`,
            },
            '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
              borderTop: `1px solid ${theme.palette.grey[200]}`,
              borderLeft: `1px solid ${theme.palette.grey[200]}`,
              borderRight: `1px solid ${theme.palette.grey[200]}`,
              borderBottom: 'none' /* not working */,
            },
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          }}
        >
          {tabs?.map((tab: any, index: number) => (
            <Tab label={tab.label} key={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
        <div
          style={{
            borderBottom: `1px solid ${theme.palette.grey[200]}`,
            flexGrow: 1,
          }}
        ></div>
      </Box>
      <Box sx={{ py: 2 }}>{tabs?.[value].component}</Box>
    </Box>
  );
}
