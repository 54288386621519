import { createApiInstance } from './createApiInstance';
import { updateCacheData } from '../../utils/commonFunctions';

const extendedApi: any = createApiInstance.injectEndpoints({
  endpoints: (build) => ({
    getSettings: build.query({
      query: () => '/api/settings',
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getDashboardSettings: build.query({
      query: () => '/api/dashboard',
      transformResponse: (res) => {
        return res;
      },
    }),
    getDashboardTextSettings: build.query({
      query: () => '/api/dashboard/dashboard-text',
      transformResponse: (res) => {
        return res;
      },
    }),
    createDashboardSettings: build.mutation({
      query(body) {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          formData.append(key, body[key]);
        });

        return {
          url: '/api/dashboard',
          method: 'POST',
          body: formData,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getSettings', (draft: any) => {
            Object.assign(draft, data);
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    createDashboardTextSettings: build.mutation({
      query(body) {
        return {
          url: '/api/dashboard/dashboard-text',
          method: 'POST',
          body: body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getSettings', (draft: any) => {
            Object.assign(draft, data);
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    createDashboardOrderSettings: build.mutation({
      query(body) {
        return {
          url: `/api/dashboard/update-order`,
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getSettings', (draft: any) => {
            Object.assign(draft, data);
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    updateDashboardSettings: build.mutation({
      query({ id, ...body }) {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          formData.append(key, body[key]);
        });

        return {
          url: `/api/dashboard/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getSettings', (draft: any) => {
            Object.assign(draft, data);
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    updateDashboardTextSettings: build.mutation({
      query({ id, ...body }) {
        return {
          url: `/api/dashboard/dashboard-text/${id}`,
          method: 'PUT',
          body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getSettings', (draft: any) => {
            Object.assign(draft, data);
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    deleteDashboardSettings: build.mutation({
      query(id) {
        return {
          url: `/api/dashboard/${id}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getSettings', (draft: any) => {
            Object.assign(draft, data);
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    deleteDashboardTextSettings: build.mutation({
      query(id) {
        return {
          url: `/api/dashboard/dashboard-text/${id}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getSettings', (draft: any) => {
            Object.assign(draft, data);
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    createCourseSettings: build.mutation({
      query({ ...body }) {
        return {
          url: '/api/settings/course-setting',
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getSettings', (draft: any) => {
            Object.assign(draft, data);
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    updateCourseSettings: build.mutation({
      query({ id, ...body }) {
        return {
          url: `/api/settings/course-setting-update/${id}`,
          method: 'PUT',
          body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getSettings', (draft: any) => {
            Object.assign(draft, data);
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    updateSettings: build.mutation({
      query({ ...body }) {
        return {
          url: '/api/settings',
          method: 'PUT',
          body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getSettings', (draft: any) => {
            Object.assign(draft, data);
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
  }),
});

export const { useGetSettingsQuery, useGetDashboardSettingsQuery, useGetDashboardTextSettingsQuery, useCreateDashboardSettingsMutation, useCreateDashboardTextSettingsMutation, useCreateDashboardOrderSettingsMutation, useUpdateDashboardSettingsMutation, useUpdateDashboardTextSettingsMutation, useDeleteDashboardSettingsMutation, useDeleteDashboardTextSettingsMutation, useCreateCourseSettingsMutation, useUpdateCourseSettingsMutation, useUpdateSettingsMutation } = extendedApi;
