// import React, { useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import useAuth from '../../context/AuthContext';

// const RedirectSSO: React.FC = () => {
//     const auth = useAuth();

//     const { lms_token, lms_refresh }: any = useParams();

//     console.log("lms_token==", lms_token);

//     useEffect(() => {
//       auth.setAuth({
//         token: lms_token,
//         refToken: lms_refresh
//       });
//       // window.location.href = 'http://localhost:3004';
//     }, [auth, lms_token, lms_refresh]);



//     return null;
//   };

//   export default RedirectSSO;

import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../context/AuthContext';
import CryptoJS from 'crypto-js';
import { useDispatch } from 'react-redux';
import { deleteAllCookies } from '../../utils/commonFunctions';

const RedirectSSO: React.FC = () => {
  const { '*': pathParams } = useParams<{ '*'?: string }>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Initialize params object
  const params: { [key: string]: string } = {};

  if (pathParams) {
    // Extract lms_token and lms_refresh from the path manually
    const lmsTokenIndex = pathParams.indexOf('lms_token=');
    const lmsRefreshIndex = pathParams.indexOf('lms_refresh=');
    const nfcIndex = pathParams.indexOf('nfc_token=');

    if (lmsTokenIndex !== -1 && lmsRefreshIndex !== -1 && nfcIndex !== -1) {
      params['lms_token'] = pathParams.slice(lmsTokenIndex + 'lms_token='.length, lmsRefreshIndex - 1);
      params['lms_refresh'] = pathParams.slice(lmsRefreshIndex + 'lms_refresh='.length, nfcIndex - 1);
      params['nfc_token'] = pathParams.slice(nfcIndex + 'nfc_token='.length);
    } else {
      params['lms_token'] = pathParams.slice(lmsTokenIndex + 'lms_token='.length, lmsRefreshIndex - 1);
      params['lms_refresh'] = pathParams.slice(lmsRefreshIndex + 'lms_refresh='.length);
    }
  }

  const lms_token = params['lms_token'];
  const lms_refresh = params['lms_refresh'];
  const nfc_token = params['nfc_token'];

  const decryptToken = (encryptedToken: any) => {
    try {
      // URL decode the token before decryption
      const cleanedToken = decodeURIComponent(encryptedToken.replace(/\s/g, '+'));
      const bytes = CryptoJS.AES.decrypt(cleanedToken, '4pvr78fybt3kqj');
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedText;
    } catch (error) {
      console.error('Decryption error:', error);
      return null; // Handle decryption error
    }
  };

  const dToken = decryptToken(lms_token);
  const dRefresh = decryptToken(lms_refresh)

  console.log(dToken, dRefresh, dToken === '', dRefresh === '');

  const auth = useAuth();

  // (lms_token !== '' && lms_refresh !== '') || (lms_token !== "null" && lms_refresh !== "null") || 

  useEffect(() => {
    // if ((dToken !== '' && dRefresh !== '')) {
      if (dToken && dRefresh) {
        auth.setAuth({
          token: dToken,
          refToken: dRefresh
        });

        if (nfc_token) {
          window.location.assign(`${process.env.REACT_APP_SERVER_REDIRECT_NFC_URL}/ssopage/?at=${nfc_token}`);
        } else {
          window.location.assign(`${process.env.REACT_APP_SERVER_REDIRECT_URL}`);
        }
      }
    // } 
    // else {
    //   console.log("Cd");
    //   if (dToken && dRefresh) {
    //     auth.setAuth({
    //       token: dToken,
    //       refToken: dRefresh
    //     });

    //     if (nfc_token) {
    //       window.location.href = `${process.env.REACT_APP_SERVER_REDIRECT_NFC_URL}/ssopage/?at=${nfc_token}`;
    //     } else {
    //       window.location.href = `${process.env.REACT_APP_SERVER_REDIRECT_URL}`;
    //     }
    //   }
    //   // auth.removeAuth();
    //   // dispatch({ type: 'logout' });
    //   // auth.setAuth({ token: '', refToken: '' });
    //   // deleteAllCookies();
    //   // navigate('/log-in');
    //   // if (nfc_token) {
    //   //   window.location.href = `${process.env.REACT_APP_SERVER_REDIRECT_NFC_URL}/ssopage/?at=${nfc_token}`;
    //   // } else {
    //   //   window.location.href = `${process.env.REACT_APP_SERVER_REDIRECT_URL}`;
    //   // }
    // }
  }, [auth]);


  return null;
};

export default RedirectSSO;
