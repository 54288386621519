import React, { useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import HookTextField from '../../../components/HookTextfield';
import Loader from '../../../components/Loader';
import LoadingButton from '../../../components/LoadingButton';
import {
    useCreateCourseSettingsMutation, useCreateDashboardOrderSettingsMutation, useCreateDashboardSettingsMutation, useCreateDashboardTextSettingsMutation, useDeleteDashboardSettingsMutation, useDeleteDashboardTextSettingsMutation, useGetDashboardSettingsQuery, useGetDashboardTextSettingsQuery, useUpdateDashboardSettingsMutation, useUpdateDashboardTextSettingsMutation,
} from '../../../store/apis/setting';
import { notify } from '../../../utils/commonFunctions';
import textConst from '../../../utils/textConst';
import validationSchemas from '../../../utils/validationSchemas';
import { useGetAllCoursesQuery, useGetAllSettingCoursesQuery } from '../../../store/apis/courses';
import HookAutoComplete from '../../../components/HookAutoComplete';
import CustomTabs from '../../../components/CustomTabs';
import CommonTableCourseSetting from '../../../components/CommonTableCourseSetting';
import { userSelector } from '../../../store/user';
import { useSelector } from 'react-redux';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Radio, Tooltip } from '@mui/material';
import AlertSettingDialog from '../../../components/AlertSettingDialog';
import SearchBarReport from '../../../components/SearchBarReport';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HookRadioGroupField from '../../../components/HookRadioGroupField';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';

import gallery from '../../../assets/images/gallery.png'
import MyEditor from '../../../components/Editor';
import MyEditorText from '../../../components/EditorText';
import AlertDialog from '../../../components/AlertDialog';


const validationSchema = yup.object({
    assessmentPassPercentage: validationSchemas.assessmentPassPercentage,
});

const DashboardSettings = () => {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('slide');
    const [imageUrl, setImageUrl] = useState<any | null>();
    const [imageUpload, setImageUpload] = useState<File | null>();
    const [expandedSlideIndices, setExpandedSlideIndices] = useState<number[]>([]);
    const [expandedSlideTextIndices, setExpandedTextSlideIndices] = useState<number[]>([]);

    const [isNewSlideAdded, setIsNewSlideAdded] = useState(true);
    const [isNewSlideTextAdded, setIsNewSlideTextAdded] = useState(true);
    const [editSlide, setEditSlide] = useState(false);
    const [editSlideText, setEditSlideText] = useState(false);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openDeleteDialogText, setOpenDeleteDialogText] = useState(false);
    const [isOrderChanged, setIsOrderChanged] = useState(false);
    const [sortedOrderSlides, setSortedSlides] = useState<any>([]);

    const [numSlides, setNumSlides] = useState(0);
    const [numSlidesText, setNumSlidesText] = useState(0);
    const [addingSlide, setAddingSlide] = useState(false);
    const [addingTextSlide, setAddingTextSlide] = useState(false);

    const [slideId, setSlideId] = useState<any | null>();
    const [slideTextId, setSlideTextId] = useState<any | null>();
    const [editedIndex, setEditedIndex] = useState<number | null>(null);
    const [editedIndexText, setEditedIndexText] = useState<number | null>(null);


    const handleOptionChange = (event: any) => {
        setSelectedOption(event.target.value);
    };

    const handleClickOpen = (slideId: any) => {
        setSlideId(slideId);
        setOpenDeleteDialog(true);
    };

    const handleClose = () => {
        setOpenDeleteDialog(false);
    };

    const handleClickOpenText = (slideId: any) => {
        setSlideTextId(slideId)
        setOpenDeleteDialogText(true);
    };

    const handleCloseText = () => {
        setOpenDeleteDialogText(false);
    };


    const {
        data,
        refetch,
        loading: slideLoading,
    } = useGetDashboardSettingsQuery();

    const {
        data: dataText,
        loading: textLoading,
        refetch: refetchText,
    } = useGetDashboardTextSettingsQuery();


    const [
        addDashboardSlide,
        {
            isLoading: isAddLoading,
            isSuccess: isAddSuccess,
            isError: isAddError,
            error: addError,
        },
    ] = useCreateDashboardSettingsMutation();

    const [
        addDashboardSlideText,
        {
            isLoading: isAddTextLoading,
            isSuccess: isAddTextSuccess,
            isError: isAddTextError,
            error: addTextError,
        },
    ] = useCreateDashboardTextSettingsMutation();

    const [
        addDashboardOrderSlide,
        {
            isLoading: isAddOrderLoading,
            isSuccess: isAddOrderSuccess,
        },
    ] = useCreateDashboardOrderSettingsMutation();

    const [
        updateDashboardSlide,
        {
            isLoading: isUpdateLoading,
            isSuccess: isUpdateSuccess,
            isError: isUpdateError,
            error: updateError,
        },
    ] = useUpdateDashboardSettingsMutation();

    const [
        updateDashboardTextSlide,
        {
            isLoading: isUpdateTextLoading,
            isSuccess: isUpdateTextSuccess,
            isError: isUpdateTextError,
            error: updateTextError,
        },
    ] = useUpdateDashboardTextSettingsMutation();

    const [
        deleteDashboardSlide,
        {
            isLoading: isDeleteLoading,
            isSuccess: isDeleteSuccess,
            isError: isDeleteError,
            error: deleteError,
        },
    ] = useDeleteDashboardSettingsMutation();

    const [
        deleteTextDashboardSlide,
        {
            isLoading: isDeleteTextLoading,
            isSuccess: isDeleteTextSuccess,
            isError: isDeleteTextError,
            error: deleteTextError,
        },
    ] = useDeleteDashboardTextSettingsMutation();

    const addNewSlide = () => {
        setIsNewSlideAdded(true);
        setNumSlides(numSlides + 1);
        const newIndex = data?.dashboardSlides?.length + 1;
        setAddingSlide(true);
        setExpandedSlideIndices([...expandedSlideIndices, newIndex]);
    };

    const addNewTextSlide = () => {
        setIsNewSlideTextAdded(true);
        setNumSlidesText(numSlides + 1);
        const newIndex = dataText?.dashboardTexts?.length + 1;
        setAddingTextSlide(true);
        setExpandedTextSlideIndices([...expandedSlideTextIndices, newIndex]);
    };

    const deleteNewSlide = () => {
        setNumSlides(numSlides - 1);
        setIsNewSlideAdded(false);
        setAddingSlide(false);
        const updatedExpandedSlideIndices = [...expandedSlideIndices];
        updatedExpandedSlideIndices.pop();
        setExpandedSlideIndices(updatedExpandedSlideIndices);
        setImageUrl(null);
        setValue(`description`, '');
    };

    const deleteNewTextSlide = () => {
        setNumSlidesText(numSlides - 1);
        setIsNewSlideTextAdded(false);
        setAddingTextSlide(false);
        const updatedExpandedTextSlideIndices = [...expandedSlideTextIndices];
        updatedExpandedTextSlideIndices.pop();
        setExpandedTextSlideIndices(updatedExpandedTextSlideIndices);
        setValue(`descriptionText`, '');
    };

    const handleSlideExpansion = (index: number) => {
        if (expandedSlideIndices.includes(index)) {
            setExpandedSlideIndices(expandedSlideIndices.filter((i) => i !== index));
        } else {
            setExpandedSlideIndices([...expandedSlideIndices, index]);
        }
    };

    const handleSlideTextExpansion = (index: number) => {
        if (expandedSlideTextIndices.includes(index)) {
            setExpandedTextSlideIndices(expandedSlideTextIndices.filter((i) => i !== index));
        } else {
            setExpandedTextSlideIndices([...expandedSlideTextIndices, index]);
        }
    };


    const saveSlide = () => {
        if (imageUpload === undefined && selectedSection === '') {
            notify(
                'All fields are Required.',
                'error',
                1200
            );
        } else if (imageUpload === undefined) {
            notify(
                'Image is Required.',
                'error',
                1200
            );
        } else {
            const bodeyData = {
                text: selectedSection,
                sliderOrder: data?.dashboardSlides?.length + 1,
                file: imageUpload
            }

            addDashboardSlide(bodeyData);

            setIsNewSlideAdded(false);
            setImageUrl(null);
            setValue(`description`, '');
        }
    };

    const saveTextSlide = () => {
        const bodeyData = {
            text: selectedTextSection,
        }
        addDashboardSlideText(bodeyData);

        setIsNewSlideTextAdded(false);
        setValue(`descriptionText`, '');
    };

    const orderMap = sortedOrderSlides.reduce((acc: any, slide: any) => {
        acc[slide._id] = slide.sliderOrder;
        return acc;
    }, {});


    const saveOrderChange = () => {
        addDashboardOrderSlide(orderMap)
    }

    const deleteOrderChange = () => {
        setSortedSlides([]);
        setIsOrderChanged(false);
    }

    const renderAddOrSaveButton = () => {
        const justifyContentValue = isOrderChanged ? 'space-between' : 'flex-end';
        if (addingSlide) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={deleteNewSlide}
                        sx={{ mr: 2 }}
                    >
                        Delete Slide
                    </Button>

                    <LoadingButton
                        sx={{
                            width: { xs: '100%', sm: 'auto' },
                        }}
                        variant="contained"
                        type="submit"
                        onClick={saveSlide}
                        disabled={isAddLoading}
                        isLoading={isAddLoading}
                    >
                        {textConst.save} Slide
                    </LoadingButton>
                </Box>
            );
        }
        return (
            <Box sx={{
                display: 'flex', justifyContent: justifyContentValue
            }}>
                {isOrderChanged &&
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            sx={{ width: { xs: '100%', sm: 'inherit' }, mr: 4 }}
                            variant="outlined"
                            color="secondary"
                            onClick={deleteOrderChange }
                        >
                            {textConst.cancel}
                        </Button>
                        <LoadingButton
                            sx={{
                                width: { xs: '100%', sm: 'auto' },
                            }}
                            variant="contained"
                            type="submit"
                            onClick={saveOrderChange}
                            disabled={isAddOrderLoading}
                            isLoading={isAddOrderLoading}
                        >
                            {textConst.save}
                        </LoadingButton>
                    </Box>
                }
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={addNewSlide}
                >
                    Add Slide
                </Button>
            </Box>

        );
    };

    const renderAddOrSaveButtonText = () => {
        if (addingTextSlide) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={deleteNewTextSlide}
                        sx={{ mr: 2 }}
                    >
                        Delete Slide
                    </Button>

                    <LoadingButton
                        sx={{
                            width: { xs: '100%', sm: 'auto' },
                        }}
                        variant="contained"
                        type="submit"
                        onClick={saveTextSlide}
                        disabled={isAddTextLoading}
                        isLoading={isAddTextLoading}
                    >
                        {textConst.save} Slide
                    </LoadingButton>
                </Box>
            );
        }
        return (
            <Box sx={{
                display: 'flex', justifyContent: 'flex-end'
            }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={addNewTextSlide}
                >
                    Add Slide
                </Button>
            </Box>

        );
    };

    const renderEditOrSaveButton = (slideId: any, index: any) => {

        if (editSlide) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton
                        sx={{
                            width: { xs: '100%', sm: 'auto' },
                        }}
                        variant="contained"
                        type="submit"
                        onClick={() => {
                            handleEdit(slideId, index)
                            setEditSlide(false);
                        }}
                        disabled={isAddLoading}
                        isLoading={isAddLoading}
                    >
                        {textConst.save} Slide
                    </LoadingButton>
                </Box>
            );
        }
        return (
            <Box sx={{
                display: 'flex', justifyContent: 'flex-end'
            }}>
                <LoadingButton
                    sx={{
                        width: { xs: '100%', sm: 'auto' },
                    }}
                    variant="contained"
                    type="submit"
                    onClick={() => {
                        setEditSlide(true);
                    }}
                    disabled={isUpdateLoading}
                    isLoading={isUpdateLoading}
                >
                    {textConst.edit} Slide
                </LoadingButton>
            </Box>

        );
    };

    const renderEditOrSaveButtonText = (slideId: any, index: any) => {
        if (editSlideText) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton
                        sx={{
                            width: { xs: '100%', sm: 'auto' },
                        }}
                        variant="contained"
                        type="submit"
                        onClick={() => {
                            handleEditText(slideId, index)
                            setEditSlideText(false);
                        }}
                        disabled={isAddLoading}
                        isLoading={isAddLoading}
                    >
                        {textConst.save} Slide
                    </LoadingButton>
                </Box>
            );
        }
        return (
            <Box sx={{
                display: 'flex', justifyContent: 'flex-end'
            }}>
                <LoadingButton
                    sx={{
                        width: { xs: '100%', sm: 'auto' },
                    }}
                    variant="contained"
                    type="submit"
                    onClick={() => {
                        setEditSlideText(true);
                    }}
                    disabled={isUpdateLoading}
                    isLoading={isUpdateLoading}
                >
                    {textConst.edit} Slide
                </LoadingButton>
            </Box>

        );
    };

    const renderNewSlide = () => {
        if (numSlides > 0 && isNewSlideAdded) {
            const newIndex = data?.dashboardSlides?.length + 1;
            return (
                <Accordion
                    key={`new-slide-${newIndex}`}
                    expanded={expandedSlideIndices.includes(newIndex)}
                    onChange={() => handleSlideExpansion(newIndex)}
                    sx={{
                        'overflow': 'hidden',
                        '&.MuiAccordion-root': {
                            borderRadius: 2,
                        },
                        mb: 4
                    }}
                >
                    <AccordionSummary
                        expandIcon={
                            <PlayArrowIcon
                                sx={{
                                    color: (theme) => theme.palette.grey[300],
                                    transform: 'rotate(90deg)',
                                }}
                            />
                        }
                        sx={{
                            'backgroundColor': (theme) => theme.palette.grey[500],
                            'color': (theme) => theme.palette.grey[100],
                            '& .MuiAccordionSummary-content': {
                                width: '100%',
                                overflow: 'hidden',
                                display: 'block',
                            },
                        }}
                    >
                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                            <LensBlurIcon
                                sx={{
                                    cursor: 'grab',
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            />
                            <Typography variant="h6" fontWeight={500}>
                                Slide {data?.dashboardSlides?.length + 1}
                            </Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>

                            <>
                                <Grid item xs={12} lg={3} md={4}>
                                    {imageUrl ?
                                        <>
                                            <Box
                                                gap={2}
                                                width={'100%'}
                                                height={'100%'}
                                                sx={{
                                                    minHeight: '173.63px',
                                                    position: 'relative',
                                                    border: (theme) =>
                                                        `2px dashed ${theme.palette.grey[300]}`,
                                                    borderRadius: 2,
                                                    background: (theme) =>
                                                        `${theme.palette.grey[100]}`,
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <CancelIcon sx={{ color: 'red', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setImageUrl(null)
                                                        }}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        borderRadius: 5,
                                                        objectFit: 'contain',
                                                        width: '100%',
                                                        height: {
                                                            xs: '150px',
                                                            md: '200px',
                                                        },
                                                    }}
                                                    component={'img'}
                                                    src={imageUrl}
                                                    alt="slide_img"
                                                >
                                                </Box>
                                            </Box>
                                        </> :
                                        <Box
                                            gap={2}
                                            width={'100%'}
                                            height={'100%'}
                                            sx={{
                                                minHeight: '173.63px',
                                                cursor: 'pointer',
                                                position: 'relative',
                                                border: (theme) =>
                                                    `2px dashed ${theme.palette.grey[300]}`,
                                                borderRadius: 2,
                                                background: (theme) =>
                                                    `${theme.palette.grey[100]}`,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column'
                                            }}
                                            onClick={() => {
                                                const fileInput = document.getElementById('account-settings-upload-image');
                                                if (fileInput) {
                                                    fileInput.click();
                                                }
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                <img
                                                    src={gallery}
                                                    alt="image_file"
                                                    width={70}
                                                    height={70}
                                                />
                                                <Typography
                                                    variant="subtitle1"
                                                    textAlign={'center'}
                                                    sx={{
                                                        color: (theme) => theme?.palette.grey[600],
                                                    }}
                                                >
                                                    Upload Image
                                                </Typography>
                                                <Typography
                                                    variant="subtitle1"
                                                    textAlign={'center'}
                                                    sx={{
                                                        px: 1,
                                                        color: (theme) => theme?.palette.grey[400],
                                                    }}
                                                >
                                                    <Box>
                                                        <Typography>
                                                            You Can Upload Only PNG & JPG.
                                                        </Typography>


                                                        <Typography sx={{ overflow: 'hidden' }}>
                                                            The ideal aspect ratio for the image is
                                                            18.9/7.
                                                        </Typography>
                                                    </Box>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    }
                                </Grid>

                                <Grid item xs={12} lg={9} md={8}>
                                    <Controller
                                        name={`description`}
                                        control={control}
                                        render={({ field: props }) => (
                                            <MyEditor
                                                initialValue={''}
                                                value={props.value}
                                                handleChange={props.onChange}
                                            />
                                        )}
                                    />

                                    <Typography
                                        variant="subtitle2"
                                        sx={{ color: (theme) => theme.palette.grey[500] }}
                                    >
                                        {remainingCount} Character{remainingCount > 0 ? '(s)' : ''}{' '}
                                        Remaining
                                    </Typography>
                                </Grid>



                                <input
                                    hidden
                                    type='file'
                                    accept='image/png, image/jpeg, image/jpg'
                                    onChange={(e) => handleInputImageChange(e)}
                                    id='account-settings-upload-image'
                                />
                            </>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            )
        }
        return null
    };

    const renderNewTextSlide = () => {
        if (numSlidesText > 0 && isNewSlideTextAdded) {
            const newIndex = dataText?.dashboardTexts?.length + 1;
            return (
                <Accordion
                    key={`new-slide-${newIndex}`}
                    expanded={expandedSlideTextIndices.includes(newIndex)}
                    onChange={() => handleSlideTextExpansion(newIndex)}
                    sx={{
                        'overflow': 'hidden',
                        '&.MuiAccordion-root': {
                            borderRadius: 2,
                        },
                        mb: 4
                    }}
                >
                    <AccordionSummary
                        expandIcon={
                            <PlayArrowIcon
                                sx={{
                                    color: (theme) => theme.palette.grey[300],
                                    transform: 'rotate(90deg)',
                                }}
                            />
                        }
                        sx={{
                            'backgroundColor': (theme) => theme.palette.grey[500],
                            'color': (theme) => theme.palette.grey[100],
                            '& .MuiAccordionSummary-content': {
                                width: '100%',
                                overflow: 'hidden',
                                display: 'block',
                            },
                        }}
                    >
                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                            <Typography variant="h6" fontWeight={500}>
                                Slide {dataText?.dashboardTexts?.length + 1}
                            </Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <>
                                <Grid item xs={12}>
                                    <Controller
                                        name={`descriptionText`}
                                        control={control}
                                        render={({ field: props }) => (
                                            <MyEditorText
                                                initialValue={''}
                                                value={props.value}
                                                handleChange={props.onChange}
                                            />
                                        )}
                                    />
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ color: (theme) => theme.palette.grey[500] }}
                                    >
                                        {remainingCountText} Character{remainingCountText > 0 ? '(s)' : ''}{' '}
                                        Remaining
                                    </Typography>
                                </Grid>
                            </>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            )
        }
        return null
    };

    const sortedSlides = data?.dashboardSlides?.slice().sort((a: any, b: any) => a.sliderOrder - b.sliderOrder);

    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        trigger,
        watch,
        formState: { errors },
    } = useForm<any>({
        defaultValues: {
            sections: selectedOption,
            description: '',
            descriptionText: '',
        },
        resolver: yupResolver(validationSchema),
        mode: 'onTouched',
        reValidateMode: 'onChange',
    });

    const selectedSection = watch('description');
    const selectedTextSection = watch('descriptionText');

    const remainingCount = useMemo(
        () => 250 - (selectedSection?.length || 0),
        [selectedSection?.length]
    );

    const remainingCountSlideText = (index: any, textLength: any) => {
        const selectedSlideTextSection = watch(`description-${index}`);
        return selectedSlideTextSection === undefined ? 250 - (textLength + (selectedSlideTextSection?.length || 0)) : 250 - (selectedSlideTextSection?.length || 0);
    };

    const remainingCountText = useMemo(
        () => 2000 - (selectedTextSection?.length || 0),
        [selectedTextSection?.length]
    );

    const remainingCountTextEdit = (index: any, textLength: any) => {
        const selectedTextSectionEdit = watch(`descriptionText-${index}`);
        return selectedTextSectionEdit === undefined ? 2000 - (textLength + (selectedTextSectionEdit?.length || 0)) : 2000 - (selectedTextSectionEdit?.length || 0);
    };


    const handleInputImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxSizeInBytes = 15 * 1024 * 1024; // 15MB

            if (allowedTypes.includes(file.type) && file.size <= maxSizeInBytes) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const imageDataUrl = e.target?.result as string;

                    setImageUrl(imageDataUrl);
                    setImageUpload(file);
                };
                reader.readAsDataURL(file);
            } else {
                if (!allowedTypes.includes(file.type)) {
                    alert('Invalid file type. Please select a valid image file.');
                } else {
                    alert('File size exceeds the allowed limit (15MB). Please choose a smaller file.');
                }
            }
        }
    };

    const onSubmit = (data: any) => {
    }

    const handleEdit = (slideId: any, index: any) => {
        const hasChanges = imageUpload !== undefined;

        setEditSlide(hasChanges ? false : true);

        const selectedSliderSection = watch(`description-${index}`);

        if (editSlide) {
            const bodyData = {
                text: selectedSliderSection,
                file: imageUpload
            }

            updateDashboardSlide({ id: slideId, ...bodyData })
            setImageUrl(null);
            setValue(`description`, '');
        }
    }

    const handleEditText = (slideId: any, index: any) => {

        const selectedTextSectionEdit = watch(`descriptionText-${index}`);

        if (editSlideText) {
            const bodyData = {
                text: selectedTextSectionEdit,
            }

            updateDashboardTextSlide({ id: slideId, ...bodyData })
            setValue(`descriptionText`, '');
        }
    }

    const handleDelete = () => {
        deleteDashboardSlide(slideId)
    }

    const handleDeleteText = () => {
        deleteTextDashboardSlide(slideTextId)
    }

    const onDragStart = (e: any, index: number) => {
        e.dataTransfer.setData('index', index);
    };

    const onSlideDragOver = (e: React.DragEvent<HTMLDivElement>, index: number) => {
        e.preventDefault();
    };

    const onSlideDrop = (e: React.DragEvent<HTMLDivElement>, dropIndex: number, slideId: any) => {
        const sourceIndex = Number(e.dataTransfer.getData('index'));
        const targetIndex = dropIndex;

        if (sourceIndex !== targetIndex) {
            const reorderedSlides = [...sortedSlides];
            const [movedSlide] = reorderedSlides.splice(sourceIndex, 1);
            reorderedSlides.splice(targetIndex, 0, movedSlide);

            const updatedSlides = reorderedSlides.map((slide, index) => ({
                ...slide,
                sliderOrder: index + 1
            }));

            setSortedSlides(updatedSlides);
            setIsOrderChanged(true);

            const updatedExpandedIndices = [...expandedSlideIndices];
            const movedIndex = updatedExpandedIndices.splice(sourceIndex, 1)[0];
            updatedExpandedIndices.splice(targetIndex, 0, movedIndex);
            setExpandedSlideIndices(updatedExpandedIndices);
        }
    };


    useEffect(() => {
        if (isDeleteSuccess) {
            handleClose()
            notify(
                `Dashboard Slide deleted successfully`,
                'success',
                1200
            );
            refetch()
        }
    }, [isDeleteSuccess, refetch]);

    useEffect(() => {
        if (isDeleteTextSuccess) {
            handleCloseText()
            notify(
                `Dashboard Text Slide deleted successfully`,
                'success',
                1200
            );
            refetchText()
        }
    }, [isDeleteTextSuccess, refetchText]);

    useEffect(() => {
        if (isUpdateSuccess && !editSlide) {
            notify(
                `Dashboard Slide updated successfully`,
                'success',
                1200
            );
            refetch()
            setEditSlide(false);
        }
    }, [isUpdateSuccess, refetch]);

    useEffect(() => {
        if (isUpdateTextSuccess && !editSlideText) {
            notify(
                `Dashboard Text Slide updated successfully`,
                'success',
                1200
            );
            setEditSlideText(false);
            refetchText()
        }
    }, [isUpdateTextSuccess, refetchText]);

    useEffect(() => {
        if (isAddSuccess) {
            notify(
                `Dashboard Slide added successfully`,
                'success',
                1200
            );
            refetch()
            setAddingSlide(false);
            setImageUrl(null)
            setImageUpload(null);

            const lastIndex = data.dashboardSlides?.length;
            setExpandedSlideIndices(prevState => [...prevState, lastIndex]);
        }
    }, [isAddSuccess, refetch]);

    useEffect(() => {
        if (isAddTextSuccess) {
            notify(
                `Dashboard Text Slide added successfully`,
                'success',
                1200
            );
            refetchText()
            setAddingTextSlide(false);

            const lastIndex = dataText.dashboardTexts?.length;
            setExpandedTextSlideIndices(prevState => [...prevState, lastIndex]);
        }
    }, [isAddTextSuccess, refetchText]);

    useEffect(() => {
        if (isAddOrderSuccess) {
            notify(
                `Dashboard Slides order changes successfully`,
                'success',
                1200
            );
            setIsOrderChanged(false);
            refetch()
        }
    }, [isAddOrderSuccess, refetch]);

    useEffect(() => {
        if (isAddError || addError || isUpdateError || updateError || isDeleteError || deleteError) {
            notify(
                addError?.data?.message ||
                updateError?.data?.message ||
                'Uploaded Image size less then or equal to 5MB!',
                'error'
            );
            setAddingSlide(false);
            setEditSlide(false);
        }
    }, [
        addError,
        isAddError,
        isUpdateError,
        updateError,
        isDeleteError,
        deleteError
    ]);

    useEffect(() => {
        if (isAddTextError || addTextError || isUpdateTextError || updateTextError || isDeleteTextError || deleteTextError) {
            notify(
                addTextError?.data?.message ||
                updateTextError?.data?.message ||
                'Something went wrong!',
                'error'
            );
            setAddingTextSlide(false);
            setEditSlideText(false);
        }
    }, [
        isAddTextError,
        addTextError,
        isUpdateTextError,
        updateTextError,
        isDeleteTextError,
        deleteTextError
    ]);


    return (
        <>
            <Grid container spacing={2} mb={3}>
                <Grid item xs={12} lg={4}>
                    <Typography
                        variant="h4"
                        fontWeight={400}
                        color={(theme) => theme.palette.grey[600]}
                    >
                        {textConst.dashboardSettings}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mb: 2 }}>
                <Stack
                    sx={{
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: { xs: 'flex-start', md: 'center' },
                    }}
                >
                    <Typography
                        mr={3}
                        sx={{ minWidth: 215 }}
                        color={(theme) => theme.palette.grey[500]}
                    >
                        What do you want to Choose?
                    </Typography>
                    <HookRadioGroupField
                        control={control}
                        defaultValue={selectedOption}
                        row={true}
                        name={`sections`}
                        handleRadioChange={(e: any) => {
                            handleOptionChange(e);
                        }}
                    >
                        <FormControlLabel
                            value="slide"
                            control={
                                <Radio
                                    sx={{
                                        '&, &.Mui-checked': {
                                            color: '#3C4764',
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography
                                    color={(theme) => theme.palette.grey[500]}
                                >
                                    Dashboard Slide
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            value="text"
                            control={
                                <Radio
                                    sx={{
                                        '&, &.Mui-checked': {
                                            color: '#3C4764',
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography
                                    color={(theme) => theme.palette.grey[500]}
                                >
                                    Dashboard Text
                                </Typography>
                            }
                        />
                    </HookRadioGroupField>
                </Stack>
            </Grid>


            <form onSubmit={handleSubmit(onSubmit)}>

                {selectedOption === 'slide' &&
                    <>
                        <React.Fragment
                        >
                            {isOrderChanged ?
                                sortedOrderSlides?.map((slide: any, index: number) => {
                                    const firstTagContent = slide?.text?.match(/<[^>]*>([^<]+)<\/[^>]*>/)[1];
                                    const truncatedText = firstTagContent?.length > 50 ? firstTagContent?.slice(0, 50) + '...' : firstTagContent;
                                    return (
                                        slide?.fileUrl &&
                                        <Accordion
                                            key={slide._id}
                                            expanded={expandedSlideIndices.includes(index)}
                                            onChange={() => handleSlideExpansion(index)}
                                            sx={{
                                                'overflow': 'hidden',
                                                '&.MuiAccordion-root': {
                                                    borderRadius: 2,
                                                },
                                                mb: 4
                                            }}
                                        >
                                            <div
                                                draggable={slide?._id !== undefined}
                                                onDragStart={(e) => onDragStart(e, index)}
                                                onDragOver={(e) => onSlideDragOver(e, index)}
                                                onDrop={(e) => onSlideDrop(e, index, slide?._id)}
                                                data-index={index}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        <PlayArrowIcon
                                                            sx={{
                                                                color: (theme) => theme.palette.grey[300],
                                                                transform: 'rotate(90deg)',
                                                            }}
                                                        />
                                                    }
                                                    sx={{
                                                        'backgroundColor': (theme) => theme.palette.grey[500],
                                                        'color': (theme) => theme.palette.grey[100],
                                                        '& .MuiAccordionSummary-content': {
                                                            width: '100%',
                                                            overflow: 'hidden',
                                                            display: 'block',
                                                        },
                                                    }}
                                                >
                                                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                                                        {slide?._id && (
                                                            <LensBlurIcon
                                                                sx={{
                                                                    cursor: 'grab',
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            />
                                                        )}
                                                        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                                            <Typography variant='h6' sx={{ width: '8%' }} fontWeight={500}>
                                                                Slide {index + 1}   </Typography>
                                                            <Typography
                                                                variant='h6'
                                                                fontWeight={500}
                                                                paragraph
                                                                dangerouslySetInnerHTML={{
                                                                    __html: truncatedText,
                                                                }}
                                                                lineHeight={'143%'}
                                                                sx={{ m: 0, width: { xs: '100%', lg: '100%' }, overflow: 'hidden' }}
                                                                letterSpacing="0.15px"
                                                            ></Typography>
                                                        </Box>
                                                    </Stack>
                                                </AccordionSummary>
                                            </div>
                                            <AccordionDetails>
                                                <Grid container spacing={3}>
                                                    <>
                                                        {/* {editSlide ? (<>
                                                        <Grid item xs={12} lg={3} md={4}>
                                                            {imageUrl ?
                                                                <>
                                                                    <Box
                                                                        gap={2}
                                                                        width={'100%'}
                                                                        height={'100%'}
                                                                        sx={{
                                                                            minHeight: '173.63px',
                                                                            position: 'relative',
                                                                            border: (theme) =>
                                                                                `2px dashed ${theme.palette.grey[300]}`,
                                                                            borderRadius: 2,
                                                                            background: (theme) =>
                                                                                `${theme.palette.grey[100]}`,
                                                                            display: 'flex',
                                                                            flexDirection: 'column'
                                                                        }}
                                                                    >
                                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                            <CancelIcon sx={{ color: 'red', cursor: 'pointer' }}
                                                                                onClick={() => {
                                                                                    setImageUrl(null)
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                        <Box
                                                                            sx={{
                                                                                borderRadius: 5,
                                                                                objectFit: 'contain',
                                                                                width: '100%',
                                                                                height: {
                                                                                    xs: '150px',
                                                                                    md: '200px',
                                                                                },
                                                                            }}
                                                                            component={'img'}
                                                                            src={imageUrl}
                                                                            alt="slide_img"
                                                                        >
                                                                        </Box>
                                                                    </Box>
                                                                </> :
                                                                <Box
                                                                    gap={2}
                                                                    width={'100%'}
                                                                    height={'100%'}
                                                                    sx={{
                                                                        minHeight: '173.63px',
                                                                        position: 'relative',
                                                                        border: (theme) =>
                                                                            `2px dashed ${theme.palette.grey[300]}`,
                                                                        borderRadius: 2,
                                                                        background: (theme) =>
                                                                            `${theme.palette.grey[100]}`,
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => {
                                                                        const fileInput = document.getElementById('account-settings-upload-image');
                                                                        if (fileInput) {
                                                                            fileInput.click();
                                                                        }
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            borderRadius: 5,
                                                                            objectFit: 'contain',
                                                                            width: '100%',
                                                                            height: {
                                                                                xs: '150px',
                                                                                md: '200px',
                                                                            },
                                                                        }}
                                                                        component={'img'}
                                                                        src={slide?.fileUrl}
                                                                        alt="slide_img"
                                                                    >
                                                                    </Box>
                                                                </Box>
                                                            }
                                                        </Grid>

                                                        <Grid item xs={12} lg={9} md={8}>
                                                            {editedIndex === index ? ( // Check if the current index is being edited
                                                                <Controller
                                                                    name={`description-${index}`}
                                                                    control={control}
                                                                    render={({ field: props }) => (
                                                                        <MyEditor
                                                                            initialValue={slide?.text}
                                                                            value={props.value}
                                                                            handleChange={props.onChange}
                                                                        />
                                                                    )}
                                                                />
                                                            ) : (
                                                                <Controller
                                                                    name={`description-${index}`}
                                                                    control={control}
                                                                    render={({ field: props }) => (
                                                                        <MyEditor
                                                                            initialValue={slide?.text}
                                                                            value={slide?.text} // Use slide.text directly for non-editable items
                                                                            handleChange={props.onChange}
                                                                            disabled
                                                                        />
                                                                    )}
                                                                />
                                                            )}
                                                            ...
                                                        </Grid>


                                                        <input
                                                            hidden
                                                            type='file'
                                                            accept='image/png, image/jpeg, image/jpg'
                                                            onChange={(e) => handleInputImageChange(e)}
                                                            id='account-settings-upload-image'
                                                        />
                                                    </>) : (<> */}
                                                        <Grid item xs={12} lg={3} md={4}>
                                                            <Box
                                                                gap={2}
                                                                width={'100%'}
                                                                height={'100%'}
                                                                sx={{
                                                                    minHeight: '173.63px',
                                                                    position: 'relative',
                                                                    border: (theme) =>
                                                                        `2px dashed ${theme.palette.grey[300]}`,
                                                                    borderRadius: 2,
                                                                    background: (theme) =>
                                                                        `${theme.palette.grey[100]}`,
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center'
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        borderRadius: 5,
                                                                        objectFit: 'contain',
                                                                        width: '100%',
                                                                        height: {
                                                                            xs: '150px',
                                                                            md: '200px',
                                                                        },
                                                                    }}
                                                                    component={'img'}
                                                                    src={slide?.fileUrl}
                                                                    alt="slide_img"
                                                                >
                                                                </Box>
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={12} lg={9} md={8}>
                                                            {/* <Controller
                                                            name={`description-${index}`}
                                                            control={control}
                                                            render={({ field: props }) => (
                                                                <MyEditor
                                                                    initialValue={slide?.text}
                                                                    value={props.value}
                                                                    handleChange={props.onChange}
                                                                    disabled
                                                                />
                                                            )}
                                                        /> */}
                                                            <Typography
                                                                fontWeight={500}
                                                                paragraph
                                                                dangerouslySetInnerHTML={{
                                                                    __html: slide?.text,
                                                                }}
                                                                lineHeight={'143%'}
                                                                sx={{ mb: 1.2, width: { xs: '100%', lg: '80%' }, overflow: 'hidden' }}
                                                                letterSpacing="0.15px"
                                                            ></Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                sx={{ color: (theme) => theme.palette.grey[500] }}
                                                            >
                                                                {/* {remainingCount} Character{remainingCount > 0 ? '(s)' : ''}{' '}
                                                            Remaining */}
                                                                {remainingCountSlideText(index, slide?.text?.length)} Character{remainingCountSlideText(index, slide?.text?.length) > 0 ? '(s)' : ''} Remaining
                                                            </Typography>
                                                        </Grid>

                                                        <input
                                                            hidden
                                                            type='file'
                                                            accept='image/png, image/jpeg, image/jpg'
                                                            onChange={(e) => handleInputImageChange(e)}
                                                            id='account-settings-upload-image'
                                                        />
                                                        {/* </>)} */}
                                                    </>

                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })
                                :
                                slideLoading ? <Loader /> :
                                    sortedSlides?.map((slide: any, index: number) => {
                                        const firstTagContent = slide?.text?.match(/<[^>]*>([^<]+)<\/[^>]*>/)[1];
                                        const truncatedText = firstTagContent?.length > 50 ? firstTagContent?.slice(0, 50) + '...' : firstTagContent;

                                        return (
                                            slide?.fileUrl &&
                                            <Accordion
                                                key={slide._id}
                                                expanded={expandedSlideIndices.includes(index)}
                                                onChange={() => handleSlideExpansion(index)}
                                                sx={{
                                                    'overflow': 'hidden',
                                                    '&.MuiAccordion-root': {
                                                        borderRadius: 2,
                                                    },
                                                    mb: 4
                                                }}
                                            >
                                                <div
                                                    draggable={slide?._id !== undefined}
                                                    onDragStart={(e) => onDragStart(e, index)}
                                                    onDragOver={(e) => onSlideDragOver(e, index)}
                                                    onDrop={(e) => onSlideDrop(e, index, slide?._id)}
                                                    data-index={index}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <PlayArrowIcon
                                                                sx={{
                                                                    color: (theme) => theme.palette.grey[300],
                                                                    transform: 'rotate(90deg)',
                                                                }}
                                                            />
                                                        }
                                                        sx={{
                                                            'backgroundColor': (theme) => theme.palette.grey[500],
                                                            'color': (theme) => theme.palette.grey[100],
                                                            '& .MuiAccordionSummary-content': {
                                                                width: '100%',
                                                                overflow: 'hidden',
                                                                display: 'block',
                                                            },
                                                        }}
                                                    >
                                                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                                                            {slide?._id && (
                                                                <LensBlurIcon
                                                                    sx={{
                                                                        cursor: 'grab',
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                />
                                                            )}
                                                            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                                                <Typography variant='h6' sx={{ width: '8%' }} fontWeight={500}>
                                                                    Slide {index + 1}   </Typography>
                                                                <Typography
                                                                    variant='h6'
                                                                    fontWeight={500}
                                                                    paragraph
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: truncatedText,
                                                                    }}
                                                                    lineHeight={'143%'}
                                                                    sx={{ m: 0, width: { xs: '100%', lg: '100%' }, overflow: 'hidden' }}
                                                                    letterSpacing="0.15px"
                                                                ></Typography>
                                                            </Box>
                                                        </Stack>
                                                    </AccordionSummary>
                                                </div>
                                                <AccordionDetails>
                                                    <Grid container spacing={3}>
                                                        <>
                                                            {editSlide && editedIndex === index ? (<>
                                                                <Grid item xs={12} lg={3} md={4}>
                                                                    {imageUrl ?
                                                                        <>
                                                                            <Box
                                                                                gap={2}
                                                                                width={'100%'}
                                                                                height={'100%'}
                                                                                sx={{
                                                                                    minHeight: '173.63px',
                                                                                    position: 'relative',
                                                                                    border: (theme) =>
                                                                                        `2px dashed ${theme.palette.grey[300]}`,
                                                                                    borderRadius: 2,
                                                                                    background: (theme) =>
                                                                                        `${theme.palette.grey[100]}`,
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column'
                                                                                }}
                                                                            >
                                                                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                                    <CancelIcon sx={{ color: 'red', cursor: 'pointer' }}
                                                                                        onClick={() => {
                                                                                            setImageUrl(null)
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                                {editedIndex === index ? (
                                                                                    <Box
                                                                                        sx={{
                                                                                            borderRadius: 5,
                                                                                            objectFit: 'contain',
                                                                                            width: '100%',
                                                                                            height: {
                                                                                                xs: '150px',
                                                                                                md: '200px',
                                                                                            },
                                                                                        }}
                                                                                        component={'img'}
                                                                                        src={imageUrl}
                                                                                        alt="slide_img"
                                                                                    >
                                                                                    </Box>
                                                                                ) : (
                                                                                    <Box
                                                                                        sx={{
                                                                                            borderRadius: 5,
                                                                                            objectFit: 'contain',
                                                                                            width: '100%',
                                                                                            height: {
                                                                                                xs: '150px',
                                                                                                md: '200px',
                                                                                            },
                                                                                        }}
                                                                                        component={'img'}
                                                                                        src={slide?.fileUrl}
                                                                                        alt="slide_img"
                                                                                    >
                                                                                    </Box>
                                                                                )}
                                                                            </Box>
                                                                        </> :
                                                                        <Box
                                                                            gap={2}
                                                                            width={'100%'}
                                                                            height={'100%'}
                                                                            sx={{
                                                                                minHeight: '173.63px',
                                                                                position: 'relative',
                                                                                border: (theme) =>
                                                                                    `2px dashed ${theme.palette.grey[300]}`,
                                                                                borderRadius: 2,
                                                                                background: (theme) =>
                                                                                    `${theme.palette.grey[100]}`,
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                justifyContent: 'center',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={() => {
                                                                                const fileInput = document.getElementById('account-settings-upload-image');
                                                                                if (fileInput) {
                                                                                    fileInput.click();
                                                                                }
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    borderRadius: 5,
                                                                                    objectFit: 'contain',
                                                                                    width: '100%',
                                                                                    height: {
                                                                                        xs: '150px',
                                                                                        md: '200px',
                                                                                    },
                                                                                }}
                                                                                component={'img'}
                                                                                src={slide?.fileUrl}
                                                                                alt="slide_img"
                                                                            >
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                </Grid>

                                                                <Grid item xs={12} lg={9} md={8}>
                                                                    {editedIndex === index ? ( // Check if the current index is being edited
                                                                        <Controller
                                                                            name={`description-${index}`}
                                                                            control={control}
                                                                            render={({ field: props }) => (
                                                                                <MyEditor
                                                                                    initialValue={slide?.text}
                                                                                    value={props.value}
                                                                                    handleChange={props.onChange}
                                                                                />
                                                                            )}
                                                                        />
                                                                    ) : (
                                                                        <Controller
                                                                            name={`description-${index}`}
                                                                            control={control}
                                                                            render={({ field: props }) => (
                                                                                <MyEditor
                                                                                    initialValue={slide?.text}
                                                                                    value={slide?.text} // Use slide.text directly for non-editable items
                                                                                    handleChange={props.onChange}
                                                                                    disabled
                                                                                />
                                                                            )}
                                                                        />
                                                                    )}

                                                                    {remainingCountSlideText(index, slide?.text?.length)} Character{remainingCountSlideText(index, slide?.text?.length) > 0 ? '(s)' : ''} Remaining
                                                                </Grid>


                                                                <input
                                                                    hidden
                                                                    type='file'
                                                                    accept='image/png, image/jpeg, image/jpg'
                                                                    onChange={(e) => handleInputImageChange(e)}
                                                                    id='account-settings-upload-image'
                                                                />
                                                            </>) : (<>
                                                                <Grid item xs={12} lg={3} md={4}>
                                                                    <Box
                                                                        gap={2}
                                                                        width={'100%'}
                                                                        height={'100%'}
                                                                        sx={{
                                                                            minHeight: '173.63px',
                                                                            position: 'relative',
                                                                            border: (theme) =>
                                                                                `2px dashed ${theme.palette.grey[300]}`,
                                                                            borderRadius: 2,
                                                                            background: (theme) =>
                                                                                `${theme.palette.grey[100]}`,
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            justifyContent: 'center'
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                borderRadius: 5,
                                                                                objectFit: 'contain',
                                                                                width: '100%',
                                                                                height: {
                                                                                    xs: '150px',
                                                                                    md: '200px',
                                                                                },
                                                                            }}
                                                                            component={'img'}
                                                                            src={slide?.fileUrl}
                                                                            alt="slide_img"
                                                                        >
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>

                                                                <Grid item xs={12} lg={9} md={8}>
                                                                    {/* <Controller
                                                                name={`description-${index}`}
                                                                control={control}
                                                                render={({ field: props }) => (
                                                                    <MyEditor
                                                                        initialValue={slide?.text}
                                                                        value={props.value}
                                                                        handleChange={props.onChange}
                                                                        disabled
                                                                    />
                                                                )}
                                                            />
                                                            <Typography
                                                                variant="subtitle2"
                                                                sx={{ color: (theme) => theme.palette.grey[500] }}
                                                            >
                                                                {remainingCountSlideText(index, slide?.text?.length)} Character{remainingCountSlideText(index, slide?.text?.length) > 0 ? '(s)' : ''} Remaining
                                                            </Typography> */}
                                                                    {/* {editedIndex === index ? ( // Check if the current index is being edited
                                                                <Controller
                                                                    name={`description-${index}`}
                                                                    control={control}
                                                                    render={({ field: props }) => (
                                                                        <MyEditor
                                                                            initialValue={slide?.text}
                                                                            value={props.value}
                                                                            handleChange={props.onChange}
                                                                        />
                                                                    )}
                                                                />
                                                            ) : (
                                                                <Controller
                                                                    name={`description-${index}`}
                                                                    control={control}
                                                                    render={({ field: props }) => (
                                                                        <MyEditor
                                                                            initialValue={slide?.text}
                                                                            value={slide?.text} // Use slide.text directly for non-editable items
                                                                            handleChange={props.onChange}
                                                                            disabled
                                                                        />
                                                                    )}
                                                                />
                                                            )} */}
                                                                    <Typography
                                                                        fontWeight={500}
                                                                        paragraph
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: slide?.text,
                                                                        }}
                                                                        lineHeight={'143%'}
                                                                        sx={{ width: { xs: '100%', lg: '80%' }, overflow: 'hidden' }}
                                                                        letterSpacing="0.15px"
                                                                    ></Typography>
                                                                </Grid>

                                                                <input
                                                                    hidden
                                                                    type='file'
                                                                    accept='image/png, image/jpeg, image/jpg'
                                                                    onChange={(e) => handleInputImageChange(e)}
                                                                    id='account-settings-upload-image'
                                                                />
                                                            </>)}
                                                        </>

                                                    </Grid>

                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, mr: 2 }}>
                                                            <LoadingButton
                                                                sx={{
                                                                    width: { xs: '100%', sm: 'auto' },
                                                                }}
                                                                variant="outlined"
                                                                type="submit"
                                                                onClick={() => {
                                                                    handleClickOpen(slide?._id)
                                                                }}
                                                                disabled={isDeleteLoading}
                                                                isLoading={isDeleteLoading}
                                                            >
                                                                {textConst.delete} Slide
                                                            </LoadingButton>
                                                        </Box>

                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                                            {/* {renderEditOrSaveButton(slide?._id, index)} */}
                                                            {editedIndex === index && editSlide ? <LoadingButton
                                                                sx={{
                                                                    width: { xs: '100%', sm: 'auto' },
                                                                }}
                                                                variant="contained"
                                                                type="submit"
                                                                onClick={() => {
                                                                    handleEdit(slide?._id, index)
                                                                    setEditSlide(false);
                                                                }}
                                                                disabled={isUpdateLoading}
                                                                isLoading={isUpdateLoading}
                                                            >
                                                                {textConst.save} Slide
                                                            </LoadingButton> : <LoadingButton
                                                                sx={{
                                                                    width: { xs: '100%', sm: 'auto' },
                                                                }}
                                                                variant="contained"
                                                                type="submit"
                                                                onClick={() => {
                                                                    setEditedIndex(index);
                                                                    setEditSlide(true);
                                                                }}
                                                                disabled={isUpdateLoading}
                                                                isLoading={isUpdateLoading}
                                                            >
                                                                {textConst.edit} Slide
                                                            </LoadingButton>}
                                                        </Box>
                                                    </Box>

                                                    {
                                                        openDeleteDialog && (
                                                            <AlertDialog
                                                                open={openDeleteDialog}
                                                                handleClose={handleClose}
                                                                isLoading={isDeleteLoading}
                                                                handleSave={() => handleDelete()}
                                                                title={textConst.areYouSure}
                                                                content={textConst.areYouSureContent}
                                                                saveBtnText={textConst.delete}
                                                            />
                                                        )
                                                    }
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    }
                                    )
                            }
                            {renderNewSlide()}
                        </React.Fragment>
                        <Box sx={{ mt: 2 }}>
                            {renderAddOrSaveButton()}
                        </Box>
                    </>
                }

                {selectedOption === 'text' &&
                    <>
                        <React.Fragment>
                            {textLoading ? <Loader /> :
                                dataText?.dashboardTexts.map((text: any, index: any) => {
                                    const firstTagContent = text?.text?.match(/<[^>]*>([^<]+)<\/[^>]*>/)[1];
                                    const truncatedText = firstTagContent?.length > 50 ? firstTagContent?.slice(0, 50) + '...' : firstTagContent;
                                    return (
                                        <Accordion
                                            key={text._id}
                                            expanded={expandedSlideTextIndices.includes(index)}
                                            onChange={() => handleSlideTextExpansion(index)}
                                            sx={{
                                                'overflow': 'hidden',
                                                '&.MuiAccordion-root': {
                                                    borderRadius: 2,
                                                },
                                                mb: 4
                                            }}
                                        >
                                            <AccordionSummary
                                                data-index={index}
                                                expandIcon={
                                                    <PlayArrowIcon
                                                        sx={{
                                                            color: (theme) => theme.palette.grey[300],
                                                            transform: 'rotate(90deg)',
                                                        }}
                                                    />
                                                }
                                                sx={{
                                                    'backgroundColor': (theme) => theme.palette.grey[500],
                                                    'color': (theme) => theme.palette.grey[100],
                                                    '& .MuiAccordionSummary-content': {
                                                        width: '100%',
                                                        overflow: 'hidden',
                                                        display: 'block',
                                                    },
                                                }}
                                            >
                                                <Stack direction={'row'} alignItems={'center'} gap={1}>
                                                    <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                                        {/* <Typography variant='h6' sx={{ width: '8%' }} fontWeight={500}>
                                                            Slide {index + 1}   </Typography> */}
                                                        <Typography
                                                            variant='h6'
                                                            fontWeight={500}
                                                            paragraph
                                                            dangerouslySetInnerHTML={{
                                                                __html: truncatedText,
                                                            }}
                                                            lineHeight={'143%'}
                                                            sx={{ m: 0, width: { xs: '100%', lg: '100%' }, overflow: 'hidden' }}
                                                            letterSpacing="0.15px"
                                                        ></Typography>
                                                    </Box>
                                                </Stack>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={3} key={index}>
                                                    {editSlideText && editedIndexText === index ?
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Controller
                                                                    name={`descriptionText-${index}`}
                                                                    control={control}
                                                                    render={({ field: props }) => (
                                                                        <MyEditorText
                                                                            initialValue={text?.text}
                                                                            value={props.value}
                                                                            handleChange={props.onChange}
                                                                        />
                                                                    )}
                                                                />
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    sx={{ color: (theme) => theme.palette.grey[500] }}
                                                                >
                                                                    {/* {remainingCountText} Character{remainingCountText > 0 ? '(s)' : ''}{' '}
                                                        Remaining */}
                                                                    {remainingCountTextEdit(index, text?.text?.length)} Character{remainingCountTextEdit(index, text?.text?.length) > 0 ? '(s)' : ''} Remaining
                                                                </Typography>
                                                            </Grid>
                                                        </> :
                                                        <>
                                                            <Grid item xs={12}>
                                                                {/* <Controller
                                                        name={`descriptionText-${index}`}
                                                        control={control}
                                                        render={({ field: props }) => (
                                                            <MyEditorText
                                                                initialValue={text?.text}
                                                                value={props.value}
                                                                handleChange={props.onChange}
                                                                disabled
                                                            />
                                                        )}
                                                    /> */}
                                                                <Typography
                                                                    fontWeight={500}
                                                                    paragraph
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: text?.text,
                                                                    }}
                                                                    lineHeight={'143%'}
                                                                    sx={{ width: { xs: '100%', lg: '80%' }, overflow: 'hidden' }}
                                                                    letterSpacing="0.15px"
                                                                ></Typography>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    sx={{ color: (theme) => theme.palette.grey[500] }}
                                                                >
                                                                    {/* {MAX_CHARACTER_COUNT - (text?.text.length ?? 0)} Character{MAX_CHARACTER_COUNT - (text?.text.length ?? 0) !== 1 ? 's' : ''} Remaining */}
                                                                    {remainingCountTextEdit(index, text?.text?.length)} Character{remainingCountTextEdit(index, text?.text?.length) > 0 ? '(s)' : ''} Remaining
                                                                </Typography>
                                                            </Grid>

                                                        </>
                                                    }
                                                </Grid>

                                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, mr: 2 }}>
                                                        <LoadingButton
                                                            sx={{
                                                                width: { xs: '100%', sm: 'auto' },
                                                            }}
                                                            variant="outlined"
                                                            type="submit"
                                                            onClick={() => {
                                                                handleClickOpenText(text?._id)
                                                            }}
                                                            disabled={isDeleteTextLoading}
                                                            isLoading={isDeleteTextLoading}
                                                        >
                                                            {textConst.delete} Slide
                                                        </LoadingButton>
                                                    </Box>

                                                    {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                            {renderEditOrSaveButtonText(text?._id, index)}
                                        </Box> */}

                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                                        {/* {renderEditOrSaveButton(slide?._id, index)} */}
                                                        {editedIndexText === index && editSlideText ? <LoadingButton
                                                            sx={{
                                                                width: { xs: '100%', sm: 'auto' },
                                                            }}
                                                            variant="contained"
                                                            type="submit"
                                                            onClick={() => {
                                                                handleEditText(text?._id, index)
                                                                setEditSlideText(false);
                                                            }}
                                                            disabled={isUpdateTextLoading}
                                                            isLoading={isUpdateTextLoading}
                                                        >
                                                            {textConst.save} Slide
                                                        </LoadingButton> : <LoadingButton
                                                            sx={{
                                                                width: { xs: '100%', sm: 'auto' },
                                                            }}
                                                            variant="contained"
                                                            type="submit"
                                                            onClick={() => {
                                                                setEditedIndexText(index);
                                                                setEditSlideText(true);
                                                            }}
                                                            disabled={isUpdateTextLoading}
                                                            isLoading={isUpdateTextLoading}
                                                        >
                                                            {textConst.edit} Slide
                                                        </LoadingButton>}
                                                    </Box>

                                                    {
                                                        openDeleteDialogText && (
                                                            <AlertDialog
                                                                open={openDeleteDialogText}
                                                                handleClose={handleCloseText}
                                                                isLoading={isDeleteTextLoading}
                                                                handleSave={() => handleDeleteText()}
                                                                title={textConst.areYouSure}
                                                                content={textConst.areYouSureContent}
                                                                saveBtnText={textConst.delete}
                                                            />
                                                        )
                                                    }
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })
                            }

                            {renderNewTextSlide()}
                            <Box sx={{ mt: 2 }}>
                                {renderAddOrSaveButtonText()}
                            </Box>

                        </React.Fragment>
                    </>
                }

            </form >
        </>
    )
};

export default DashboardSettings;
