// import React from 'react';

// import Box from '@mui/material/Box';
// import CircularProgress from '@mui/material/CircularProgress';
// import { Navigate, useRoutes } from 'react-router-dom';

// import { AuthProvider, AuthRedirect } from './context/AuthContext';
// import PublicPageLayout from './layouts/PublicPageLayout';
// import AssessmentRouting from './pages/assessment/AssessmentRouting';
// import Profile from './pages/profile/Profile';
// import { getCookie } from './utils/commonFunctions';
// import ReportRouting from './pages/report/ReportRouting';
// import SettingRouting from './pages/settings/SettingRouting';
// import SetPassword from './pages/activate-account/ActivateAccount';
// import RedirectSSO from './pages/redirect-sso/RedirectSSO';
// const GetCertificate = React.lazy(
//   () => import('./pages/get-certificate/GetCertificate')
// );
// const CourseDetails = React.lazy(
//   () => import('./pages/learning/course-details/CourseDetails')
// );

// const CoursesRouting = React.lazy(
//   () => import('./pages/courses/CoursesRouting')
// );

// const AuthLayout = React.lazy(() => import('./layouts/AuthLayout'));
// const Edit = React.lazy(() => import('./pages/profile/Edit'));
// const Login = React.lazy(() => import('./pages/log-in/LogIn'));
// const ForgotPassword = React.lazy(
//   () => import('./pages/forgot-password/ForgotPassword')
// );
// const ResetPassword = React.lazy(
//   () => import('./pages/reset-password/ResetPassword')
// );
// const Home = React.lazy(() => import('./pages/home/Home'));
// const UsersRouting = React.lazy(() => import('./pages/users/UsersRouting'));

// const MyTeam = React.lazy(() => import('./pages/my-team/MyTeam'));
// const ChangePassword = React.lazy(
//   () => import('./pages/change-password/ChangePassword')
// );

// const AddEditAssessment = React.lazy(
//   () => import('./pages/assessment/AddEditAssessment')
// );
// const Settings = React.lazy(() => import('./pages/settings/courseSetting/Settings'));
// const LearningRouting = React.lazy(
//   () => import('./pages/learning/LearningRouting')
// );

// export default function AppRouting() {
//   const userToken = getCookie('token');

//   const defaultNavigate = <Navigate to={userToken ? '/home' : '/log-in'} />;
//   // const defaultNavigate = userToken ? <Navigate to={userToken && '/home'} /> : window.location.assign(`${process.env.REACT_APP_SERVER_REDIRECT_URL}`);

//   const getRouteWrapper = (
//     component: React.ReactNode,
//     authRoute = true
//   ): React.ReactNode => {
//     return (
//       <AuthRedirect authenticatedRoute={authRoute}>
//         <React.Suspense
//           fallback={
//             <Box
//               width="100%"
//               height={authRoute ? '100vh' : '100%'}
//               display="flex"
//               justifyContent="center"
//               alignItems="center"
//             >
//               <CircularProgress />
//             </Box>
//           }
//         >
//           {component}
//         </React.Suspense>
//       </AuthRedirect>
//     );
//   };

//   const routes = [
//     {
//       path: '/home',
//       element: getRouteWrapper(<AuthLayout />),
//       children: [
//         {
//           index: true,
//           element: getRouteWrapper(<Home />),
//         },
//       ],
//     },
//     {
//       path: '/users',
//       element: getRouteWrapper(<AuthLayout />),
//       children: [
//         {
//           index: true,
//           path: '*',
//           element: getRouteWrapper(<UsersRouting />),
//         },
//       ],
//     },
//     {
//       path: '/assessment',
//       element: getRouteWrapper(<AuthLayout />),
//       children: [
//         {
//           index: true,
//           path: '*',
//           element: getRouteWrapper(<AssessmentRouting />),
//         },
//       ],
//     },
//     {
//       path: '/change-password',
//       element: getRouteWrapper(<AuthLayout />),
//       children: [
//         {
//           index: true,
//           element: getRouteWrapper(<ChangePassword />),
//         },
//       ],
//     },
//     {
//       path: '/settings',
//       element: getRouteWrapper(<AuthLayout />),
//       children: [
//         {
//           index: true,
//           path: '*',
//           element: getRouteWrapper(<SettingRouting />),
//         },
//       ],
//     },
//     {
//       path: '/report',
//       element: getRouteWrapper(<AuthLayout />),
//       children: [
//         {
//           index: true,
//           path: '*',
//           element: getRouteWrapper(<ReportRouting />),
//         },
//       ],
//     },
//     {
//       path: '/my-team',
//       element: getRouteWrapper(<AuthLayout />),
//       children: [
//         {
//           index: true,
//           element: getRouteWrapper(<MyTeam />),
//         },
//         {
//           path: 'user',
//           element: getRouteWrapper(<MyTeam />),
//         },
//         {
//           path: 'user/:userId',
//           element: getRouteWrapper(<MyTeam />),
//         },
//         {
//           path: 'user/:userId/course/:courseId',
//           element: getRouteWrapper(<CourseDetails />),
//         },
//         {
//           path: 'user/:userId/edit',
//           element: getRouteWrapper(<Edit />),
//         },
//       ],
//     },
//     {
//       path: '/learning',
//       element: getRouteWrapper(<AuthLayout />),
//       children: [
//         {
//           index: true,
//           path: '*',
//           element: getRouteWrapper(<LearningRouting />),
//         },
//       ],
//     },
//     {
//       path: '/courses',
//       element: getRouteWrapper(<AuthLayout />),
//       children: [
//         {
//           index: true,
//           path: '*',
//           element: getRouteWrapper(<CoursesRouting />),
//         },
//       ],
//     },
//     {
//       path: '/profile',
//       element: getRouteWrapper(<AuthLayout />),
//       children: [
//         {
//           index: true,
//           element: getRouteWrapper(<Profile />),
//         },
//         {
//           path: 'edit',
//           element: getRouteWrapper(<Edit />),
//         },
//       ],
//     },
//     {
//       path: '/add-assessment',
//       element: getRouteWrapper(<AuthLayout />),
//       children: [
//         {
//           index: true,
//           element: getRouteWrapper(<AddEditAssessment />),
//         },
//       ],
//     },
//     {
//       path: '/change-password',
//       element: getRouteWrapper(<AuthLayout />),
//       children: [
//         {
//           index: true,
//           element: getRouteWrapper(<ChangePassword />),
//         },
//       ],
//     },
//     {
//       path: '/',
//       element: getRouteWrapper(<PublicPageLayout />, false),
//       children: [
//         // {
//         //   path: '/log-in',
//         //   element: getRouteWrapper(<Login />, false),
//         // },
//         {
//           path: '/activate-account/:token',
//           element: getRouteWrapper(<SetPassword />, false),
//         },
//         {
//           path: '/forgot-password',
//           element: getRouteWrapper(<ForgotPassword />, false),
//         },
//         {
//           path: '/reset-password/:token',
//           element: getRouteWrapper(<ResetPassword />, false),
//         },
//         // {
//         //   path: '/redirect-sso/:lms_token/:lms_refresh',
//         //   element: getRouteWrapper(<RedirectSSO />, false),
//         // },
//         {
//           path: '/redirect-sso/*',
//           element: getRouteWrapper(<RedirectSSO />, false),
//         },        
//         {
//           index: true,
//           element: defaultNavigate,
//         },
//       ],
//     },
//     {
//       path: '/get-certificate/:id',
//       element: <GetCertificate />,
//     },
//     {
//       path: '*',
//       element: defaultNavigate,
//     },
//   ];

//   const routing = useRoutes(routes);

//   return (
//     <AuthProvider>
//       <>{routing}</>
//     </AuthProvider>
//   );
// }


import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate, useRoutes } from 'react-router-dom';
import { AuthProvider, AuthRedirect } from './context/AuthContext';
import PublicPageLayout from './layouts/PublicPageLayout';
import AssessmentRouting from './pages/assessment/AssessmentRouting';
import Profile from './pages/profile/Profile';
import { getCookie } from './utils/commonFunctions';
import ReportRouting from './pages/report/ReportRouting';
import SettingRouting from './pages/settings/SettingRouting';
import SetPassword from './pages/activate-account/ActivateAccount';
import RedirectSSO from './pages/redirect-sso/RedirectSSO';
const GetCertificate = React.lazy(() => import('./pages/get-certificate/GetCertificate'));
const CourseDetails = React.lazy(() => import('./pages/learning/course-details/CourseDetails'));
const CoursesRouting = React.lazy(() => import('./pages/courses/CoursesRouting'));
const AuthLayout = React.lazy(() => import('./layouts/AuthLayout'));
const Edit = React.lazy(() => import('./pages/profile/Edit'));
const Login = React.lazy(() => import('./pages/log-in/LogIn'));
const ForgotPassword = React.lazy(() => import('./pages/forgot-password/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./pages/reset-password/ResetPassword'));
const Home = React.lazy(() => import('./pages/home/Home'));
const UsersRouting = React.lazy(() => import('./pages/users/UsersRouting'));
const MyTeam = React.lazy(() => import('./pages/my-team/MyTeam'));
const ChangePassword = React.lazy(() => import('./pages/change-password/ChangePassword'));
const AddEditAssessment = React.lazy(() => import('./pages/assessment/AddEditAssessment'));
const Settings = React.lazy(() => import('./pages/settings/courseSetting/Settings'));
const LearningRouting = React.lazy(() => import('./pages/learning/LearningRouting'));

export default function AppRouting() {
  const userToken = getCookie('token');

  // Define the redirect function
  const getDefaultNavigate = () => {
    if (userToken) {
      return <Navigate to='/home' />;
    } else {
      window.location.assign(`${process.env.REACT_APP_SERVER_REDIRECT_URL}`);
      return null; // Render nothing while redirecting externally
    }
  };

  const getRouteWrapper = (component: React.ReactNode, authRoute = true): React.ReactNode => {
    return (
      <AuthRedirect authenticatedRoute={authRoute}>
        <React.Suspense
          fallback={
            <Box
              width="100%"
              height={authRoute ? '100vh' : '100%'}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          }
        >
          {component}
        </React.Suspense>
      </AuthRedirect>
    );
  };

  const routes = [
    {
      path: '/home',
      element: getRouteWrapper(<AuthLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<Home />),
        },
      ],
    },
    {
      path: '/users',
      element: getRouteWrapper(<AuthLayout />),
      children: [
        {
          index: true,
          path: '*',
          element: getRouteWrapper(<UsersRouting />),
        },
      ],
    },
    {
      path: '/assessment',
      element: getRouteWrapper(<AuthLayout />),
      children: [
        {
          index: true,
          path: '*',
          element: getRouteWrapper(<AssessmentRouting />),
        },
      ],
    },
    {
      path: '/change-password',
      element: getRouteWrapper(<AuthLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<ChangePassword />),
        },
      ],
    },
    {
      path: '/settings',
      element: getRouteWrapper(<AuthLayout />),
      children: [
        {
          index: true,
          path: '*',
          element: getRouteWrapper(<SettingRouting />),
        },
      ],
    },
    {
      path: '/report',
      element: getRouteWrapper(<AuthLayout />),
      children: [
        {
          index: true,
          path: '*',
          element: getRouteWrapper(<ReportRouting />),
        },
      ],
    },
    {
      path: '/my-team',
      element: getRouteWrapper(<AuthLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<MyTeam />),
        },
        {
          path: 'user',
          element: getRouteWrapper(<MyTeam />),
        },
        {
          path: 'user/:userId',
          element: getRouteWrapper(<MyTeam />),
        },
        {
          path: 'user/:userId/course/:courseId',
          element: getRouteWrapper(<CourseDetails />),
        },
        {
          path: 'user/:userId/edit',
          element: getRouteWrapper(<Edit />),
        },
      ],
    },
    {
      path: '/learning',
      element: getRouteWrapper(<AuthLayout />),
      children: [
        {
          index: true,
          path: '*',
          element: getRouteWrapper(<LearningRouting />),
        },
      ],
    },
    {
      path: '/courses',
      element: getRouteWrapper(<AuthLayout />),
      children: [
        {
          index: true,
          path: '*',
          element: getRouteWrapper(<CoursesRouting />),
        },
      ],
    },
    {
      path: '/profile',
      element: getRouteWrapper(<AuthLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<Profile />),
        },
        {
          path: 'edit',
          element: getRouteWrapper(<Edit />),
        },
      ],
    },
    {
      path: '/add-assessment',
      element: getRouteWrapper(<AuthLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<AddEditAssessment />),
        },
      ],
    },
    {
      path: '/change-password',
      element: getRouteWrapper(<AuthLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<ChangePassword />),
        },
      ],
    },
    {
      path: '/',
      element: getRouteWrapper(<PublicPageLayout />, false),
      children: [
        {
          path: '/activate-account/:token',
          element: getRouteWrapper(<SetPassword />, false),
        },
        {
          path: '/forgot-password',
          element: getRouteWrapper(<ForgotPassword />, false),
        },
        {
          path: '/reset-password/:token',
          element: getRouteWrapper(<ResetPassword />, false),
        },
        {
          path: '/redirect-sso/*',
          element: getRouteWrapper(<RedirectSSO />, false),
        },
        {
          index: true,
          element: getDefaultNavigate(), // Use the redirect function here
        },
      ],
    },
    {
      path: '/get-certificate/:id',
      element: <GetCertificate />,
    },
    {
      path: '*',
      element: getDefaultNavigate(), // Use the redirect function here
    },
  ];

  const routing = useRoutes(routes);

  return (
    <AuthProvider>
      <>{routing}</>
    </AuthProvider>
  );
}
