import React, { useEffect, useState } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useController } from 'react-hook-form';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type MuiAutocompleteProps = {
  control: any;
  name: string;
  label: string;
  options: any[];
  defaultValue?: any;
  isRequired?: boolean;
  multiple?: boolean;
  onChangeVal?: any;
  getOptionLabel: string;
  getOption?: string;
  onSearchValueChange?: any;
  [key: string]: any;
  loading?: any;
  limitTags?: any;
};

const HookAutoComplete: React.FC<MuiAutocompleteProps> = ({
  control,
  name,
  label,
  options = [],
  limitTags,
  defaultValue,
  isRequired = true,
  multiple = true,
  onChangeVal,
  getOptionLabel = '',
  getOption = '',
  onSearchValueChange,
  loading,
  ...rest
}) => {
  const {
    field: { onChange, value, onBlur },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setIsSearching(loading);
  }, [loading]);

  return (
    <>
      <Autocomplete
        {...rest}
        multiple={multiple}
        disableClearable={!value}
        disableCloseOnSelect={multiple}
        limitTags={limitTags || 3}
        onSelect={(e: any) => {
          if (e.target.value) {
            setIsSearching(true);
          }
          onSearchValueChange?.(e.target.value || '');
        }}
        loading={isSearching || loading}
        options={options || []}
        getOptionLabel={(option: any) =>
          getOption.length
            ? `${option[getOptionLabel]} - ${option[getOption]}`
            : option[getOptionLabel] || ''
        }
        isOptionEqualToValue={(option, value) => option?._id === value?._id}
        renderOption={(props, option: any, { selected }) => (
          <li {...props}>
            {multiple && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
            {getOption.length
              ? `${option[getOptionLabel]} - ${option[getOption]}`
              : option[getOptionLabel]}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            error={invalid}
            label={
              <Stack direction="row" gap={0.5}>
                {label}
                {isRequired && (
                  <Typography
                    sx={{ color: (theme) => theme.palette.primary.dark }}
                  >
                    *
                  </Typography>
                )}
              </Stack>
            }
          />
        )}
        onBlur={onBlur}
        onChange={(e: any, value: any) => {
          onChange(value);
          onChangeVal?.(value);
          if (!value) {
            onSearchValueChange?.(e.target.value || '');
          }
        }}
        value={value || null}
      />
      {invalid && (
        <FormHelperText
          sx={{
            color: (theme) => theme.palette.primary.dark,
            m: '3px 14px 0px',
          }}
        >
          {error?.message}
        </FormHelperText>
      )}
    </>
  );
};

export default HookAutoComplete;
