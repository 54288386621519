import * as yup from 'yup';

/**
 * Common validations
 */
const nameRegex = /^[aA-zZ\s]+$/;

const emailRegex =
  /^([a-z][a-z0-9_]*|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/;

// const mobileNumberRegex = /^[0-9]{10}$/g;

// const mobileNumberRegex = /^[+]?[0-9]{0,3}?[-\s.]?[0-9]{10}$/;
const mobileNumberRegex = /^[+]?[0-9]{0,3}?[-.]?[0-9\s]{10}$/;

// const mobileNumberRegex =
//   /^[+]?[0-9]{1,3}?[-\s.]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,9}$/;
const validationSchemas = {
  email: yup
    .string()
    .trim()
    .email('Invalid email address')
    .required('Email is required')
    .matches(emailRegex, 'Enter valid email'),
  categoryName: yup
    .string()
    .trim()
    .nullable()
    .required('Category name is required')
    .min(3, 'Category name is too short - should be 3 chars minimum')
    .max(50, 'Category name is too long - should be 50 chars'),
  courseName: yup
    .string()
    .trim()
    .nullable()
    .required('Course name is required')
    .min(3, 'Course name is too short - should be 3 chars minimum')
    .max(50, 'Course name is too long - should be 50 chars'),
  password: yup
    .string()
    .trim()
    .required('Password is required')
    .min(8, 'Password should be of minimum 8 characters length')
    .max(20, 'Password should be of maximum 20 characters length')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      'Password should contain at least one lowercase letter, one uppercase letter, one number, and one special character'
    ),
  confirm_password: yup
    .string()
    .trim()
    .required('Password is required')
    .min(8, 'Password should be of minimum 8 characters length')
    .max(20, 'Password should be of maximum 20 characters length')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      'Password should contain at least one lowercase letter, one uppercase letter, one number, and one special character'
    ),
  firstName: yup
    .string()
    .trim()
    .trim()
    .required('First name is required')
    .matches(nameRegex, 'Only alphabets are allowed'),
  lastName: yup
    .string()
    .trim()
    .required('Last name is required')
    .matches(nameRegex, 'Only alphabets are allowed'),
  // mobileNumber: yup
  //   .string()
  //   .trim()
  //   .required('Mobile number is required')
  //   .matches(mobileNumberRegex, 'Enter valid phone number'),
  mobileNumber: yup
    .string()
    .trim()
    .required('Mobile number is required')
    .matches(/^\d{4} \d{3} \d{3}$/, 'Enter valid phone number format'),
  categoryIds: yup
    .array()
    .min(1, 'Choose at least 1 category')
    .required('Category is required'),
  assessmentCategory: yup
    .object()
    .nullable()
    .test(
      'is-not-empty',
      'Assessment Category is required',
      (value) => !!(value && Object.keys(value).length !== 0) // Return true if the object has no keys (i.e., it's empty).
    ),
  assessmentQuestionChoice: yup
    .array()
    .of(
      yup.object().shape({
        choiceText: yup.string().trim().required('Answer text is required'),
        isRight: yup.boolean().default(false),
      })
    )
    .test(
      'atLeastOneRightChoice',
      'At least one choice must be correct',
      (choices: any) => {
        return choices?.some((choice: any) => choice.isRight === true);
      }
    ),

  role: yup.object().required('Role is required'),
  manager: yup
    .array()
    .min(1, 'Choose at least 1 manager')
    .required('Manager(s) required'),
  client: yup.string().required('Client is required'),
  summary: yup.string(),
  assignCourse: yup
    .array()
    .min(1, 'Choose at least 1 assign course')
    .required('Assign Course(s) required'),
  selection: yup.string().required('Please select an option'),
  title: yup
    .string()
    .nullable()
    .required('Title is required')
    .min(3, 'Title is too short - should be 3 chars minimum')
    .max(50, 'Title is too long - should be 15 chars'),
  question: yup.string().nullable().required('Question is required'),
  option: yup.object().nullable().required('Option is required'),
  answer: yup.string().nullable().required('Answer is required'),
  assessmentPassPercentage: yup
    .string()
    .required('Assessment Pass Percentage is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .test(
      'test less than',
      'Please enter a value from 1 to 100',
      (value: any) => value <= 100 && value > 0
    ),
  recompletionDuration: yup
    .string()
    .required('Recompletion Duration is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .test(
      'test less than',
      'Please enter a value from 1 to 999',
      (value: any) => value <= 999 && value > 0
    ),
  dueDays: yup
    .string()
    .required('Days is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .test(
      'test grater than',
      'Days must be greater than 0',
      (value: any) => value > 0
    ),
};

export default validationSchemas;
