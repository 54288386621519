import React from 'react';

import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Outlet } from 'react-router-dom';

import loginBackgroundImg from './../assets/images/login-background.png';
import theme from '../theme-loader/theme';
import textConst from '../utils/textConst';

const PublicPageLayout: React.FC = () => {
  const isExtraLarge = useMediaQuery('(min-width:1700px)');

  return (
    <Grid container height={'100vh'}>
      <Grid
        item
        xs={12}
        md={6}
        lg={7.6}
        sx={{
          display: { xs: 'none', md: 'block' },
          backgroundImage: `url(${loginBackgroundImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
        }}
      ></Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4.4}
        py={2}
        display="flex"
        flexDirection="column"
        sx={{
          px: isExtraLarge ? 15 : { xs: 5, sm: 8, md: 6, lg: 7, xl: 10 },
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'center'}
          height={'100%'}
          flexDirection={'column'}
        >
          <Outlet />
        </Box>
        <Typography variant="subtitle2" textAlign={'center'}>
          © {moment().year()}{' '}
          <span style={{ color: theme.palette.primary.main }}>
            {textConst.iCorp}
          </span>{' '}
          {textConst.security + '. ' + textConst.allRightsReserved}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PublicPageLayout;
