const fontSize = 16;
export const htmlFontSize = 16;

const fontSizeCalc = (expectedBodyFontSize: any) => {
  return (14 / 16) * expectedBodyFontSize;
};

export const pxToRem = (size: any) => `${size / htmlFontSize}rem`;

export const themeTypography = {
  htmlFontSize,
  fontSize: fontSizeCalc(fontSize),
  fontFamily: ['Roboto'].join(','),
  h1: {
    fontSize: pxToRem(96),
  },
  h2: {
    fontSize: pxToRem(60),
  },
  h3: {
    fontSize: pxToRem(48),
  },
  h4: {
    fontSize: pxToRem(34),
  },
  h5: {
    fontSize: pxToRem(24),
  },
  h6: {
    fontSize: pxToRem(20),
  },
  subtitle1: {
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontSize: pxToRem(14),
  },
  body1: {
    fontSize: pxToRem(16),
  },
  body2: {
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  buttonLarge: {
    fontSize: pxToRem(15),
  },
  buttonMedium: {
    fontSize: pxToRem(14),
  },
  buttonSmall: {
    fontSize: pxToRem(13),
  },
  caption: {
    fontSize: pxToRem(12),
  },
  overline: {
    fontSize: pxToRem(12),
  },
  avatarLetter: {
    fontSize: pxToRem(20),
  },
  inputLabel: {
    fontSize: pxToRem(12),
  },
  helperText: {
    fontSize: pxToRem(12),
  },
  inputText: {
    fontSize: pxToRem(16),
  },
  tooltip: {
    fontSize: pxToRem(10),
  },
};
