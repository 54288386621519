import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loader from './Loader';

const MyEditorText = ({ handleChange, value, initialValue, disabled }: any) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <Loader />}
      <Editor
        apiKey="2o46z04mlu84tqawwqff9pkfrcicyqsyra8w9ud06161kfue"
        onInit={() => {
          setLoading(false);
        }}
        cloudChannel="5-stable"
        disabled={disabled}
        initialValue={initialValue}
        inline={false}
        onEditorChange={(val: any) => {
          if (val.length <= 2000) {
            handleChange?.(val);
          } 
        }}
        plugins=""
        tagName="div"
        textareaName=""
        value={value}
        init={{
          height: 300,
          min_height: 300,
          language: 'en',
          branding: false,
          menubar: false,
          statusbar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
        }}
      />
    </>
  );
};

export default MyEditorText;