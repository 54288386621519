import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import textConst from '../../utils/textConst';
import validationSchemas from '../../utils/validationSchemas';
import HookTextField from '../../components/HookTextfield';
import LoadingButton from '../../components/LoadingButton';
import LogoHeader from '../../components/LogoHeader';
import { useActivateAccountMutation, useLogInMutation } from '../../store/apis/auth';
import theme from '../../theme-loader/theme';
import { encryptField, notify } from '../../utils/commonFunctions';


const validationSchema = yup.object({
    password: validationSchemas.password,
    confirm_password: yup
        .string()
        .required('Confirm Password is required')
        .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value || value === null;
        }),
});


const ActivateAccount: React.FC = () => {
    const navigate = useNavigate();

    const { token }: any = useParams();

    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);


    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);


    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };


    const [logInPost, { data, isSuccess, isLoading, isError, error }] =
        useActivateAccountMutation();

    const { handleSubmit, control, watch } = useForm({
        defaultValues: {
            password: '',
            confirm_password: ''
        },
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (data: any) => {
        logInPost({ token: token, newPassword: data?.confirm_password });
    };

    useEffect(() => {
        if (isError) {
            notify(error?.data?.message, 'error');
        }
        if (error?.status === 'FETCH_ERROR') {
            notify('Please try again later.', 'error');
        }
    }, [error, isError]);

    useEffect(() => {
        if (isSuccess) {
            notify('Account activate successfully', 'success');
            navigate('/login');
        }
    }, [isSuccess]);

    const password = watch('password');
    const confirm_password = watch('confirm_password');

    const isDisable = !(password && confirm_password);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: '14%' }}>
                <LogoHeader />
                <Stack mt={2} mb={6}>
                    <Typography
                        textAlign="center"
                        variant="body2"
                        color={(theme) => theme.palette.grey[400]}
                    >
                        {textConst.welcomeToTheText}{' '}
                        <span
                            style={{
                                fontWeight: 600,
                                color: theme.palette.primary.main,
                            }}
                        >
                            {textConst.iCorp}
                        </span>{' '}
                        {textConst.security + ' ' + textConst.onlineLearningSystem + '.'}
                    </Typography>
                </Stack>
                <Grid
                    container
                    gap={2}
                    sx={{ px: { xs: 2, sm: 10, md: 3, lg: 2, xl: 4 } }}
                    justifyContent={'center'}
                >
                    <Grid item xs={12}>
                        <HookTextField
                            name="password"
                            control={control}
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            sx={{ color: (theme) => theme.palette.grey[300] }}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <HookTextField
                            name="confirm_password"
                            control={control}
                            label="Confirm Password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            sx={{ color: (theme) => theme.palette.grey[300] }}
                                        >
                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Box width={'100%'} style={{ cursor: 'not-allowed' }}>
                        <LoadingButton
                            isLoading={isLoading}
                            variant="contained"
                            type="submit"
                            fullWidth
                            sx={{
                                p: 2,
                            }}
                            disabled={isDisable || isLoading}
                        >
                            Set Password
                        </LoadingButton>
                    </Box>
                </Grid>
            </form>
        </>
    );
};

export default ActivateAccount;
