// import React, { useMemo, useEffect } from 'react';

// import Search from '@mui/icons-material/Search';
// import TextField from '@mui/material/TextField';
// import { useSearchParams } from 'react-router-dom';
// import CloseIcon from '@mui/icons-material/Close';

// type SearchBarProps = {
//   placeHolder?: string;
//   size?: 'small' | 'medium';
//   onKeyUp?: any;
//   resetSearch?: boolean | any;
//   handleClose?: any;
// };

// const SearchBarReport = ({ placeHolder, size, onKeyUp, resetSearch, handleClose }: SearchBarProps) => {
//   const [searchParams, setSearchParams] = useSearchParams();

//   const search = useMemo(() => {
//     return searchParams.get('search') || '';
//   }, [searchParams]);

//   useEffect(() => {
//     if (resetSearch) {
//       searchParams.delete('search');
//       setSearchParams(searchParams);
//     }
//   }, [resetSearch, searchParams, setSearchParams]);

//   const handleClearSearch = () => {
//     searchParams.delete('search');
//     setSearchParams(searchParams);
//   };

//   return (
//     <TextField
//       size={size || 'medium'}
//       placeholder={placeHolder || 'Search'}
//       sx={{
//         borderRadius: 4,
//         width: '100%',
//         input: {
//           '&::placeholder': {
//             color: (theme) => theme.palette.grey[400],
//             opacity: 1,
//           },
//         },
//       }}
//       onKeyUp={(e: any) => {
//         onKeyUp(e);
//         const trimmedValue = e.target.value.trim();
//         if (trimmedValue.length) {
//           searchParams.set('search', trimmedValue);
//         } else {
//           searchParams.delete('search');
//         }
//         setSearchParams(searchParams);
//       }}
//       InputProps={{
//         startAdornment: (
//           <Search
//             sx={{
//               color: (theme) => theme.palette.grey[500],
//               mr: 1,
//             }}
//           />
//         ),
//         endAdornment: (
//           <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => { handleClearSearch(); handleClose(); }} />
//         )
//       }}
//     />
//   );
// };

// export default SearchBarReport;


import React, { useEffect, useState } from 'react';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { useSearchParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

type SearchBarProps = {
  placeHolder?: string;
  size?: 'small' | 'medium';
  onKeyUp?: (value: string) => void;
  resetSearch?: boolean | any;
  handleClose?: () => void;
};

const SearchBarReport = ({ placeHolder, size, onKeyUp, resetSearch, handleClose }: SearchBarProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    if (resetSearch) {
      searchParams.delete('search');
      setSearchParams(searchParams);
      setSearchValue('');
    }
  }, [resetSearch, searchParams, setSearchParams]);

  const handleClearSearch = () => {
    searchParams.delete('search');
    setSearchParams(searchParams);
    setSearchValue('');
    handleClose && handleClose();
  };

  const handleKeyUp = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const trimmedValue = e.target.value.trim();
    // setSearchValue(trimmedValue);
    const trimmedValue = e.target.value;
    setSearchValue(trimmedValue);

    if (onKeyUp) {
      onKeyUp(trimmedValue);
    }

    if (trimmedValue.length) {
      searchParams.set('search', trimmedValue);
    } else {
      searchParams.delete('search');
    }

    setSearchParams(searchParams);
  };


  return (
    <TextField
      size={size || 'medium'}
      placeholder={placeHolder || 'Search'}
      sx={{
        borderRadius: 4,
        width: '100%',
        input: {
          '&::placeholder': {
            color: (theme) => theme.palette.grey[400],
            opacity: 1,
          },
        },
      }}
      value={searchValue}
      onChange={handleKeyUp}
      InputProps={{
        startAdornment: (
          <Search
            sx={{
              color: (theme) => theme.palette.grey[500],
              mr: 1,
            }}
          />
        ),
        endAdornment: (
          <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClearSearch} />
        )
      }}
    />
  );
};

export default SearchBarReport;
