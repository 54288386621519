import { createSlice } from '@reduxjs/toolkit';

import { Role } from '../utils/types';

type InitState = {
  roles: Role[];
};

const initialState = {
  roles: [],
};

export const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoles: (state: any, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setRoles } = roleSlice.actions;

export const roleSelector = (state: InitState) => state.roles;

export default roleSlice.reducer;
