import React from 'react';

import { Controller } from 'react-hook-form';

type HookCheckboxFieldProps = {
  children: React.ReactElement[];
  defaultValue: string;
  name: string;
  control: any;
};
const HookCheckboxField: React.FC<HookCheckboxFieldProps> = ({
  name,
  control,
  children,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      defaultValue={[]}
      render={({ field }) => <>{children}</>}
    />
  );
};

export default HookCheckboxField;
