import { createApiInstance } from './createApiInstance';
import {
  queryParamsBuilder,
  updateCacheData,
} from '../../utils/commonFunctions';

const extendedApi: any = createApiInstance.injectEndpoints({
  endpoints: (build) => ({
    getAllAssessments: build.query({
      query: (query) => `/api/assessments${queryParamsBuilder(query)}`,
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getAssessment: build.query({
      query: (id) => `/api/assessments/${id}`,
      transformResponse: (res) => {
        return res.data;
      },
    }),
    addAssessment: build.mutation({
      // query(body) {
      //   return {
      //     url: '/api/assessments',
      //     method: 'POST',
      //     body: body,
      //   };
      // },
      query(body) {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          formData.append(key, body[key]);
        });

        return {
          url: '/api/assessments',
          method: 'POST',
          body: formData,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;

          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getAllAssessments', (draft: any) => {
            const index = draft.assessments.findIndex(
              (item: any) => item._id === data?._id
            );
            if (index !== -1) {
              draft.assessments.splice(index, 1, data);
            } else {
              draft.assessments.push(data);
            }
          });

          updateCache('getTeamMembers', (draft: any) => {
            const index = draft.teamMembers.findIndex(
              (item: any) => item._id === data._id
            );
            if (index !== -1) {
              draft.teamMembers.splice(index, 1, data);
            } else {
              draft.teamMembers.push(data);
            }
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    deleteAssessment: build.mutation({
      query(id) {
        return {
          url: `/api/assessments/${id}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getAllAssessments', (draft: any) => {
            const index = draft.assessments.findIndex(
              (item: any) => item._id === data?._id || patch
            );
            draft.assessments.splice(index, 1);
            draft.pagination.totalCount = draft.pagination.totalCount - 1;
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    deleteAssessmentQuestion: build.mutation({
      query({ assessmentId, questionId }) {
        return {
          url: `/api/assessments/${assessmentId}/question/${questionId}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;

          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };
          updateCache('getAllAssessments', (draft: any) => {
            const index = draft.assessments.findIndex(
              (item: any) =>
                item._id === data?.assessmentId || patch.assessmentId
            );
            draft.assessments.splice(index, 1);
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getAllAssessmentCategories: build.query({
      query: (query) =>
        `/api/assessment-categories${queryParamsBuilder(query)}`,
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getAssessmentCategoryById: build.query({
      query: (id) => `/api/assessment-categories/${id}`,
      transformResponse: (res) => {
        return res.data;
      },
    }),
    addAssessmentCategory: build.mutation({
      query(body) {
        return {
          url: '/api/assessment-categories',
          method: 'POST',
          body: body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;

          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getAllAssessments', (draft: any) => {
            const index = draft.assessments.findIndex(
              (item: any) => item._id === data?._id
            );
            if (index !== -1) {
              draft.assessments.splice(index, 1, data);
            } else {
              draft.assessments.push(data);
            }
          });

          updateCache('getTeamMembers', (draft: any) => {
            const index = draft.teamMembers.findIndex(
              (item: any) => item._id === data._id
            );
            if (index !== -1) {
              draft.teamMembers.splice(index, 1, data);
            } else {
              draft.teamMembers.push(data);
            }
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    updateAssessmentCategory: build.mutation({
      query({ id, ...body }) {
        return {
          url: `/api/assessment-categories/${id}`,
          method: 'PUT',
          body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getAssessmentCategoryById', (draft: any) => {
            Object.assign(draft, data);
          });

          updateCache('getAllAssessmentCategories', (draft: any) => {
            const index = draft.assessments.findIndex(
              (item: any) => item._id === data?._id
            );
            if (index !== -1) {
              draft.assessments.splice(index, 1, data);
            } else {
              draft.assessments.push(data);
            }
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    updateAssessment: build.mutation({
      // query({ id, ...body }) {
      //   return {
      //     url: `/api/assessments/${id}`,
      //     method: 'PUT',
      //     body,
      //   };
      // },
      query({ id, ...body }) {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          formData.append(key, body[key]);
        });

        return {
          url: `/api/assessments/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getAssessment', (draft: any) => {
            Object.assign(draft, data);
          });

          updateCache('getAllAssessments', (draft: any) => {
            const index = draft.assessments.findIndex(
              (item: any) => item._id === data?._id
            );
            if (index !== -1) {
              draft.assessments.splice(index, 1, data);
            } else {
              draft.assessments.push(data);
            }
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    updateAssessmentOrder: build.mutation({
      query({ id, ...body }) {
        return {
          url: `/api/assessments/update-question-order/${id}`,
          method: 'PUT',
          body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getAssessment', (draft: any) => {
            Object.assign(draft, data);
          });

          updateCache('getAllAssessments', (draft: any) => {
            const index = draft.assessments.findIndex(
              (item: any) => item._id === data?._id
            );
            if (index !== -1) {
              draft.assessments.splice(index, 1, data);
            } else {
              draft.assessments.push(data);
            }
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    deleteAssessmentCategory: build.mutation({
      query(id) {
        return {
          url: `/api/assessment-categories/${id}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };
          updateCache('getAllAssessments', (draft: any) => {
            const index = draft.assessments.findIndex(
              (item: any) => item._id === data?._id || patch
            );
            draft.assessments.splice(index, 1);
            draft.pagination.totalCount = draft.pagination.totalCount - 1;
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getAssessmentWithCategory: build.query({
      query: (query) =>
        `/api/assessments/assessment-category-section${queryParamsBuilder(
          query
        )}`,
      transformResponse: (res) => {
        return res.data;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllAssessmentsQuery,
  useGetAssessmentQuery,
  useAddAssessmentMutation,
  useDeleteAssessmentMutation,
  useDeleteAssessmentQuestionMutation,
  useGetAllAssessmentCategoriesQuery,
  useGetAssessmentCategoryByIdQuery,
  useAddAssessmentCategoryMutation,
  useUpdateAssessmentCategoryMutation,
  useDeleteAssessmentCategoryMutation,
  useUpdateAssessmentMutation,
  useUpdateAssessmentOrderMutation,
  useGetAssessmentWithCategoryQuery,
} = extendedApi;
