import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import HookTextField from '../../../components/HookTextfield';
import Loader from '../../../components/Loader';
import LoadingButton from '../../../components/LoadingButton';
import {
    useCreateCourseSettingsMutation,
} from '../../../store/apis/setting';
import { notify } from '../../../utils/commonFunctions';
import textConst from '../../../utils/textConst';
import validationSchemas from '../../../utils/validationSchemas';
import { useGetAllCoursesQuery, useGetAllSettingCoursesQuery } from '../../../store/apis/courses';
import HookAutoComplete from '../../../components/HookAutoComplete';
import CustomTabs from '../../../components/CustomTabs';
import CommonTableCourseSetting from '../../../components/CommonTableCourseSetting';
import { userSelector } from '../../../store/user';
import { useSelector } from 'react-redux';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Radio, Tooltip } from '@mui/material';
import AlertSettingDialog from '../../../components/AlertSettingDialog';
import SearchBarReport from '../../../components/SearchBarReport';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HookRadioGroupField from '../../../components/HookRadioGroupField';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';

import gallery from '../../../assets/images/gallery.png'
import MyEditor from '../../../components/Editor';


const validationSchema = yup.object({
    assessmentPassPercentage: validationSchemas.assessmentPassPercentage,
});

const AddDashboardSettings = () => {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('slide');
    const [imageUrl, setImageUrl] = useState<any | null>();

    const handleOptionChange = (event: any) => {
        setSelectedOption(event.target.value);
    };

    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        trigger,
        watch,
        formState: { errors },
    } = useForm<any>({
        defaultValues: {
            sections: selectedOption,
            description: '',
        },
        resolver: yupResolver(validationSchema),
        mode: 'onTouched',
        reValidateMode: 'onChange',
    });

    const selectedSection = watch('description');

    const handleClick = () => {
        navigate('/dashboard-setting/new');
    };



    const handleInputImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxSizeInBytes = 15 * 1024 * 1024; // 15MB

            if (allowedTypes.includes(file.type) && file.size <= maxSizeInBytes) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const imageDataUrl = e.target?.result as string;

                    setImageUrl(imageDataUrl);
                    //   setImageUpload(file);
                    //   setValue(`questions[${questions?.length - 1}].file`, file);
                };
                reader.readAsDataURL(file);
            } else {
                if (!allowedTypes.includes(file.type)) {
                    alert('Invalid file type. Please select a valid image file.');
                } else {
                    alert('File size exceeds the allowed limit (15MB). Please choose a smaller file.');
                }
            }
        }
    };


    return (
        <>
            <Grid container spacing={2} mb={3}>
                <Grid item xs={12} lg={4}>
                    <Typography
                        variant="h4"
                        fontWeight={400}
                        color={(theme) => theme.palette.grey[600]}
                    >
                        {textConst.add} {textConst.dashboardSettings}
                    </Typography>
                </Grid>
            </Grid>

            <React.Fragment
            // key={slide.id}
            >
                <Accordion
                    // expanded={slideIndex === expandedSlideIndex}
                    // expanded={expandedSlideIndices.includes(slideIndex)}
                    // onChange={handleSlideChange(slideIndex, slide?._id)}
                    sx={{
                        'overflow': 'hidden',
                        '&.MuiAccordion-root': {
                            borderRadius: 2,
                        },
                    }}
                >
                    <AccordionSummary
                        // draggable={slide?._id !== undefined}
                        // onDragStart={(e) => onSlideDragStart(e, slideIndex)}
                        // onDragOver={onSlideDragOver}
                        // onDrop={onSlideDrop}
                        // data-index={slideIndex}
                        expandIcon={
                            <PlayArrowIcon
                                sx={{
                                    color: (theme) => theme.palette.grey[300],
                                    transform: 'rotate(90deg)',
                                }}
                            />
                        }
                        sx={{
                            'backgroundColor': (theme) => theme.palette.grey[500],
                            'color': (theme) => theme.palette.grey[100],
                            '& .MuiAccordionSummary-content': {
                                width: '100%',
                                overflow: 'hidden',
                                display: 'block',
                            },
                        }}
                    >
                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                            {/* {slide?._id && ( */}
                            <LensBlurIcon
                                sx={{
                                    cursor: 'grab',
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            />
                            {/* // )} */}
                            <Typography variant="h6" fontWeight={500}>
                                Slide
                                {/* {slideIndex + 1} : {slide?.type === 'pdf' ? slide?.title : slide?.type === 'quiz' ? slide?.questionText : 'Image'} */}
                            </Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Stack
                                    sx={{
                                        flexDirection: { xs: 'column', md: 'row' },
                                        alignItems: { xs: 'flex-start', md: 'center' },
                                    }}
                                >
                                    <Typography
                                        mr={3}
                                        sx={{ minWidth: 215 }}
                                        color={(theme) => theme.palette.grey[500]}
                                    >
                                        What do you want to Choose?
                                    </Typography>
                                    <HookRadioGroupField
                                        control={control}
                                        defaultValue={selectedOption}
                                        row={true}
                                        name={`sections`}
                                        handleRadioChange={(e: any) => {
                                            handleOptionChange(e);
                                        }}
                                    >
                                        <FormControlLabel
                                            value="slide"
                                            // disabled={
                                            //   saveSlideClick || slideIndex !== fields.length - 1
                                            // }
                                            control={
                                                <Radio
                                                    sx={{
                                                        '&, &.Mui-checked': {
                                                            color:
                                                                // slide?._id
                                                                //     ? 'rgba(0, 0, 0, 0.26)'
                                                                //     : 
                                                                '#3C4764',
                                                        },
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    color={(theme) => theme.palette.grey[500]}
                                                >
                                                    Dashboard Slide
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            value="text"
                                            // disabled={
                                            //   saveSlideClick || slideIndex !== fields.length - 1
                                            // }
                                            control={
                                                <Radio
                                                    sx={{
                                                        '&, &.Mui-checked': {
                                                            color:
                                                                // slide?._id
                                                                //     ? 'rgba(0, 0, 0, 0.26)'
                                                                //     : 
                                                                '#3C4764',
                                                        },
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    color={(theme) => theme.palette.grey[500]}
                                                >
                                                    Dashboard Text
                                                </Typography>
                                            }
                                        />
                                    </HookRadioGroupField>
                                </Stack>
                            </Grid>

                            {selectedOption === 'slide' &&
                                <>
                                    <Grid item xs={12} lg={3} md={4}>
                                        {imageUrl ?
                                            <>
                                                <Box
                                                    gap={2}
                                                    width={'100%'}
                                                    height={'100%'}
                                                    sx={{
                                                        minHeight: '173.63px',
                                                        position: 'relative',
                                                        border: (theme) =>
                                                            `2px dashed ${theme.palette.grey[300]}`,
                                                        borderRadius: 2,
                                                        background: (theme) =>
                                                            `${theme.palette.grey[100]}`,
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <CancelIcon sx={{ color: 'red', cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setImageUrl(null)
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            borderRadius: 5,
                                                            objectFit: 'contain',
                                                            width: '100%',
                                                            height: {
                                                                xs: '150px',
                                                                md: '200px',
                                                            },
                                                        }}
                                                        component={'img'}
                                                        src={imageUrl}
                                                        alt="slide_img"
                                                    >
                                                    </Box>
                                                </Box>
                                            </> :
                                            <Box
                                                gap={2}
                                                width={'100%'}
                                                height={'100%'}
                                                sx={{
                                                    minHeight: '173.63px',
                                                    cursor: 'pointer',
                                                    position: 'relative',
                                                    border: (theme) =>
                                                        `2px dashed ${theme.palette.grey[300]}`,
                                                    borderRadius: 2,
                                                    background: (theme) =>
                                                        `${theme.palette.grey[100]}`,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column'
                                                }}
                                                onClick={() => {
                                                    const fileInput = document.getElementById('account-settings-upload-image');
                                                    if (fileInput) {
                                                        fileInput.click();
                                                    }
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img
                                                        src={gallery}
                                                        alt="image_file"
                                                        width={70}
                                                        height={70}
                                                    />
                                                    <Typography
                                                        variant="subtitle1"
                                                        textAlign={'center'}
                                                        sx={{
                                                            color: (theme) => theme?.palette.grey[600],
                                                        }}
                                                    >
                                                        Upload Image
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle1"
                                                        textAlign={'center'}
                                                        sx={{
                                                            px: 1,
                                                            color: (theme) => theme?.palette.grey[400],
                                                        }}
                                                    >
                                                        <Box>
                                                            <Typography>
                                                                You Can Upload Only PNG & JPG.
                                                            </Typography>
                                                        </Box>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                    </Grid>

                                    <Grid item xs={12} lg={9} md={8}>
                                        <Controller
                                            name="description"
                                            control={control}
                                            render={({ field: props }) => (
                                                <MyEditor
                                                    initialValue={''}
                                                    value={props.value}
                                                    handleChange={props.onChange}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <input
                                        hidden
                                        type='file'
                                        accept='image/png, image/jpeg, image/jpg'
                                        onChange={(e) => handleInputImageChange(e)}
                                        id='account-settings-upload-image'
                                    />


                                </>
                            }
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button
                                variant="contained"
                                color="secondary"
                            // onClick={() => {
                            //     const newSlideItemIndex = fields.length;
                            //     append(defaultSlideData);

                            //     // Add the newly added slide to the expanded indices
                            //     const updatedExpandedIndices = [...expandedSlideIndices, newSlideItemIndex];
                            //     setExpandedSlideIndices(updatedExpandedIndices);
                            // }}
                            >
                                Save Slide
                            </Button>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </React.Fragment >
        </>
    )
};

export default AddDashboardSettings;
