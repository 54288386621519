import { createApiInstance } from './createApiInstance';

const extendedApi: any = createApiInstance.injectEndpoints({
  endpoints: (build) => ({
    getAllRoles: build.query({
      query: (query) => '/api/roles',
      transformResponse: (res) => {
        return res.data;
      },
    }),

    // getAllSystemAccess: build.query({
    //   query: (email) => `http//localhost:9009/api/v1/get/system/access/${email}`,
    //   transformResponse: (res) => {
    //     return res.data;
    //   },
    // }),
  }),
  overrideExisting: false,
});

export const { useGetAllRolesQuery, useGetAllSystemAccessQuery } = extendedApi;
