import { combineReducers, configureStore } from '@reduxjs/toolkit';

import apiInstance from './apis/createApiInstance';
import roles from './role';
import user from './user';

const rootReducer = combineReducers({
  user,
  roles,
  [apiInstance.reducerPath]: apiInstance.reducer,
});

const reducer = (state: any, action: any) => {
  if (action.type === 'logout') {
    state = {};
  }
  return rootReducer(state, action);
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiInstance.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
