import { createApiInstance } from './createApiInstance';

const extendedApi: any = createApiInstance.injectEndpoints({
  endpoints: (build) => ({
    logIn: build.mutation({
      query(body) {
        return {
          url: '/auth/login',
          method: 'POST',
          body,
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    activateAccount: build.mutation({
      query(body) {
        return {
          url: '/api/users/set-password',
          method: 'POST',
          body,
        };
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    forgotPassword: build.mutation({
      query(body) {
        return {
          url: '/auth/forgot-password',
          method: 'POST',
          body,
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    resetPassword: build.mutation({
      query(body) {
        return {
          url: '/auth/reset-password',
          method: 'POST',
          body,
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    verifyToken: build.query({
      query: (token) => `/auth/verify-token/${token}`,
      transformResponse: (res) => {
        return res.data;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLogInMutation,
  useActivateAccountMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyTokenQuery,
} = extendedApi;
