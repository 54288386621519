import React from 'react';

import type { Theme } from '@emotion/react';
import type { SxProps } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import { Controller } from 'react-hook-form';

/**
 * Common component for controlled Radio group input
 */

type HookRadioGroupFieldProps = {
  children: any;
  defaultValue: string;
  name: string;
  control: any;
  row?: boolean;
  sx?: SxProps<Theme>;
  handleRadioChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: any;
};
const HookRadioGroupField: React.FC<HookRadioGroupFieldProps> = ({
  name,
  control,
  defaultValue,
  handleRadioChange,
  children,
  row,
  sx,
  onChange,
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field }) => {
        return (
          <RadioGroup
            sx={{ width: '100%' }}
            row={row}
            {...field}
            onChange={(e) => {
              onChange?.(e);
              field.onChange(e);
              handleRadioChange?.(e);
            }}
          >
            {children}
          </RadioGroup>
        );
      }}
    />
  );
};

export default HookRadioGroupField;
