import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = () => {
  return (
    <Box
      height={'100%'}
      width={'100%'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      my={2}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loader;
