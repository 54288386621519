import React from 'react';

import Chip from '@mui/material/Chip';

type CustomChipProps = {
  label?: string;
  sx?: any;
  [key: string]: any;
};

const CustomChip = ({ label, sx, ...rest }: CustomChipProps) => {
  return (
    <Chip
      {...rest}
      label={label}
      color="primary"
      sx={{
        'height': '20px',
        'fontSize': '12px',
        'lineHeight': '20px',
        'letterSpacing': '0.14px',
        '.MuiChip-label': {
          padding: '0px 7px',
        },
        ...sx,
      }}
    />
  );
};

export default CustomChip;
