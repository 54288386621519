import { createApiInstance } from './createApiInstance';
import {
  queryParamsBuilder,
  updateCacheData,
} from '../../utils/commonFunctions';

const extendedApi: any = createApiInstance.injectEndpoints({
  endpoints: (build) => ({
    getAllCourses: build.query({
      query: (query) => `/api/courses${queryParamsBuilder(query)}`,
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getAllSettingCourses: build.query({
      query: (query) => `/api/settings/course-setting${queryParamsBuilder(query)}`,
      transformResponse: (res) => {
        return res;
      },
    }),
    getCourse: build.query({
      query({ id, userId, isCertificate }) {
        return {
          url: `/api/courses/${id}${queryParamsBuilder({
            isCertificate: isCertificate ?? '',
            userId: userId ?? null,
          })}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getCourseInformation: build.query({
      query({ id, userId }) {
        return {
          url: `/api/course-assignment/get-course-score/${id}${queryParamsBuilder({
            userId: userId ?? null,
          })}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getCourseNotAssignedUsers: build.query({
      query({ id, search }) {
        return {
          url: `/api/users/not-assigned/${id}${queryParamsBuilder({
            search: search ?? null
          })}`,
          method: 'GET',
          headers: {
            'Cache-Control': 'no-cache', // Prevents caching by the browser
            'Pragma': 'no-cache', // For older HTTP/1.0 clients
            'If-Modified-Since': '0' // Ensures the server does not return 304
          }
        };
      },
      transformResponse: (res) => {
        console.log("res=====>", res);
        return res;
      },
    }),
    getCourseFailInformation: build.query({
      query({ id, userId }) {
        return {
          url: `/api/course-assignment/get-failed-attempt/${id}${queryParamsBuilder({
            userId: userId ?? null,
          })}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getCourseAdmin: build.query({
      query: (id) => `/api/courses/${id}`,
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getCourseById: build.query({
      query: (id) => `/api/courses/${id}`,
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getCourseFeedback: build.query({
      query: (id) => `/api/feedbacks/${id}`,
      transformResponse: (res) => {
        return res;
      },
    }),
    getSearchedCourses: build.query({
      query: (query) => `/api/courses${queryParamsBuilder(query)}`,
      transformResponse: (res) => {
        return res.data;
      },
    }),
    // sendCourseRemainder: build.query({
    //   query(id) {
    //     return {
    //       url: `/api/course-assignment/send-reminder-course/${id}`,
    //       method: 'GET',
    //     };
    //   },
    // }),
    sendCourseRemainder: build.query({
      query({ id, courseId  }) {
        // const queryString = courseIds ? `?courseIds=${courseIds.join('&courseIds=')}` : '';
        return {
          url: `/api/course-assignment/send-reminder-course/${id}?courseIds=${courseId}`,
          //  ${queryString}`,
          method: 'GET',
        };
      },
    }), 
    createCourse: build.mutation({
      query(body) {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          formData.append(key, body[key]);
        });
        return {
          url: '/api/courses',
          method: 'POST',
          body: formData,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: 'getAllCourses',
              dispatch,
              extendedApi,
            },
            (draft: any) => {
              const index = draft.courses.findIndex((item: any) => {
                return item._id === data?._id;
              });
              if (index !== -1) {
                draft.courses?.splice(index, 1, data);
              } else {
                draft.courses.push(data);
              }
            }
          );
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    updateCourse: build.mutation({
      query({ id, ...body }) {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          formData.append(key, body[key]);
        });

        return {
          url: `/api/courses/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getCourse', (draft: any) => {
            Object.assign(draft, data);
            const index = draft.courses.findIndex(
              (item: any) => item._id === data?._id
            );
            draft.courses?.splice(index, 1, data);
          });

          updateCache('getAllCourses', (draft: any) => {
            const index = draft.courses.findIndex(
              (item: any) => item._id === data._id
            );
            if (index !== -1) {
              draft.courses?.splice(index, 1, data);
            } else {
              draft.courses.push(data);
            }
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    updateCourseAssignment: build.mutation({
      query({ courseAssignmentId, ...body }) {
        return {
          url: `/api/course-assignment/${courseAssignmentId}/course`,
          method: 'PUT',
          body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getCourse', (draft: any) => {
            Object.assign(draft, data);
            const index = draft.courses?.findIndex(
              (item: any) => item._id === data?._id
            );
            draft.courses?.splice(index, 1, data);
          });

          updateCache('getAllCourses', (draft: any) => {
            const index = draft.courses?.findIndex(
              (item: any) => item._id === data?._id
            );

            if (index !== -1) {
              draft.courses?.splice(index, 1, data);
            } else {
              draft.courses?.push(data);
            }
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getCoursesAssignmentSection: build.query({
      query: ({ courseAssignmentId, sectionId }) =>
        `/api/course-assignment/${courseAssignmentId}/section/${sectionId}`,
      transformResponse: (res) => {
        return res.data;
      },
    }),
    submitCourseAssessment: build.mutation({
      query({ courseAssignmentId, ...body }) {
        return {
          url: `/api/course-assignment/${courseAssignmentId}/assessment`,
          method: 'PUT',
          body,
        };
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    submitFeedback: build.mutation({
      query(body) {
        return {
          url: '/api/feedbacks',
          method: 'POST',
          body,
        };
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    deleteCourse: build.mutation({
      query(id) {
        return {
          url: `/api/courses/${id}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: 'getAllCourses',
              dispatch,
              extendedApi,
            },
            (draft: any) => {
              const index = draft.courses.findIndex(
                (item: any) => item._id === data?._id || patch
              );
              draft.courses?.splice(index, 1);
              draft.pagination.totalCount = draft.pagination.totalCount - 1;
            }
          );
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllCoursesQuery,
  useGetAllSettingCoursesQuery,
  useGetCourseQuery,
  useGetCourseInformationQuery,
  useGetCourseNotAssignedUsersQuery,
  useGetCourseFailInformationQuery,
  useGetCourseAdminQuery,
  useLazyGetCourseByIdQuery,
  useGetCourseFeedbackQuery,
  useGetSearchedCoursesQuery,
  useLazySendCourseRemainderQuery,
  useCreateCourseMutation,
  useUpdateCourseMutation,
  useDeleteCourseMutation,
  useUpdateCourseAssignmentMutation,
  useSubmitCourseAssessmentMutation,
  useGetCoursesAssignmentSectionQuery,
  useSubmitFeedbackMutation,
} = extendedApi;
