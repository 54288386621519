import React from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import AddEditAssessment from './AddEditAssessment';
import AddEditCategory from './category/add-edit-category/AddEditCategory';

const AssessmentList = React.lazy(() => import('./AssessmentList'));

const CategoryList = React.lazy(() => import('./category/CategoryList'));

const AssessmentRouting = () => {
  const defaultNavigate = <Navigate to={'/assessment/list'} />;
  const routes = [
    {
      path: '/',
      children: [
        {
          index: true,
          element: defaultNavigate,
        },
        {
          path: '/list',
          element: <AssessmentList />,
        },
        {
          path: '/list/new',
          element: <AddEditAssessment />,
        },
        {
          path: '/list/:id/edit',
          element: <AddEditAssessment />,
        },
        {
          path: '/categories',
          element: <CategoryList />,
        },
        {
          path: '/categories/new',
          element: <AddEditCategory />,
        },
        {
          path: '/categories/:id/edit',
          element: <AddEditCategory />,
        },
      ],
    },
  ];
  return useRoutes(routes);
};

export default AssessmentRouting;
