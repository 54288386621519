import React, { useEffect, useState } from 'react';

// import MoreVertIcon from '@mui/icons-material/MoreVert';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BlobProvider } from '@react-pdf/renderer';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CustomChip from '../../../components/CustomChip';
import { GenerateCertificatePdf } from '../../../components/generate-pdf/GenerateCertificatePdf';
import Loader from '../../../components/Loader';
import PieChart from '../../../components/PieChart';
import { useGetAllCoursesQuery } from '../../../store/apis/courses';
import { userSelector } from '../../../store/user';
import { convertChartData, notify } from '../../../utils/commonFunctions';
import { chartData } from '../../../utils/constant';
import textConst from '../../../utils/textConst';
import CommonTableUser from '../../../components/CommonTableUser';
import { useGetAllUserCourseQuery } from '../../../store/apis/user';
import SearchBarReport from '../../../components/SearchBarReport';
import HookAutoComplete from '../../../components/HookAutoComplete';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PieChartForCourseUser from '../../../components/PieChartForCourse/PieChartForCourseUser';
// import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';


const Report = () => {
    const user = useSelector(userSelector);

    const validationSchema = yup.object({});

    const [searchVal, setSearchVal] = useState<any>(null);
    const [searchCourseVal, setSearchCourseVal] = useState<any>(null);
    const [searchUserVal, setSearchUserVal] = useState<any>(null);
    const [sortField, setSortField] = useState<any>(null);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

    const { control } = useForm<any>({
        resolver: yupResolver(validationSchema),
    });

    const navigate = useNavigate();

    const {
        data: courseData,
        isLoading: isCourseLoading,
        isError: isCourseError,
        error: courseError,
        isFetching,
    } = useGetAllCoursesQuery();

    useEffect(() => {
        if (isCourseError) {
            notify(courseError?.data?.message, 'error', 1200);
        }
    }, [courseError?.data?.message, isCourseError]);


    const {
        data: userCourses,
        isLoading: userCoursesLoading,
    } = useGetAllUserCourseQuery();

    interface User {
        _id: string;
        firstName: string;
        lastName: string;
    }

    interface Course {
        _id: string;
        name: string;
    }

    const userList: User[] = [];
    const courseList: Course[] = [];

    courseData?.courses.map((course: any) => {
        if (course?.isDraft === false) {
            courseList.push({
                _id: course._id,
                name: course.name,
            });
        }
        return [];
    });


    const filteredUsers = userCourses?.courses
        .filter((data: any) =>
            data?.user && !data?.user?.deletedBy
            // && data?.status !== 'Recompletion'
        )
        .map((data: any) => ({
            _id: data?.user?._id,
            name: data?.user?.firstName + ' ' + data?.user?.lastName
        }));

    if (filteredUsers) {
        filteredUsers.forEach((user: any) => {
            const isDuplicate = userList.some(
                (existingUser: any) =>
                    existingUser.name === user.name
            );

            if (!isDuplicate) {
                userList.push(user);
            }
        });
    }

    const allCourseName: string[] = [];

    courseData?.courses.map((name: any) => {
        name.isDraft !== true && allCourseName.push(name.name);
    })


    const homeCourseColumns: any[] = [
        {
            id: 'learning',
            label: 'Learning',
            field: 'learning',
            format: (row: any) => {
                return (
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                color: (theme) => theme.palette.grey[500],
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {row?.course?.name}
                        </Typography>
                        {row?.completedAt && row?.status === 'Completed' ? (
                            <BlobProvider
                                document={
                                    <GenerateCertificatePdf
                                        userName={`${user?.firstName} ${user?.lastName}`}
                                        courseName={row?.course?.name}
                                        courseCompletionDate={row?.completedAt}
                                    />
                                }
                            >
                                {({ url }) => (
                                    <a
                                        href={url || ''}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={(e: any) => e.stopPropagation()}
                                    >
                                        <WorkspacePremiumIcon color="primary" />
                                    </a>
                                )}
                            </BlobProvider>
                        ) : null}
                    </Box>
                );
            },
        },
        {
            id: 'status',
            label: 'Status',
            minWidth: 130,
            field: 'status',
            format: (row: any) => {
                const data = chartData.find((item) => item.text === row.status);
                if (!data) {
                    return null;
                }
                return (
                    <CustomChip
                        label={row.status}
                        color="primary"
                        sx={{
                            background: data.color,
                        }}
                    />
                );
            },
        },
        {
            id: 'due',
            label: 'Due',
            field: 'due',
            minWidth: 150,
            format: (row: any) =>
                row?.dueAt ? moment(row.dueAt).format('DD/MM/YYYY') : '-',
        },
    ];

    if (isCourseLoading) {
        return <Loader />
    }

    // const exportToExcel = () => {
    //     const filteredCourses = userCourses?.courses.filter((course: any) => {
    //         return allCourseName.some((courseName) => {
    //             console.log(course?.course?.name, courseName, course?.course?.name === courseName);
    //             return course?.course?.name === courseName && !course?.user?.deletedBy && course?.user;
    //         });
    //     });

    //     if (filteredCourses && filteredCourses.length > 0) {
    //         const groupedData: { [key: string]: any[] } = {};

    //         filteredCourses.forEach((course: any) => {
    //             const courseName = course.course.name;

    //             if (!groupedData[courseName]) {
    //                 groupedData[courseName] = [];
    //             }

    //             groupedData[courseName].push({
    //                 Course_Name: course.course.name,
    //                 Username: course.user?.firstName + ' ' + course.user?.lastName,
    //                 Status: course.status,
    //                 Score: course.score ? `${course.score}%` : '-',
    //                 Completed_Date: course.completedAt ? moment(course.completedAt).format('DD/MM/YYYY') : '-',
    //                 Due_Date: course.dueAt ? moment(course.dueAt).format('DD/MM/YYYY') : '-',
    //             });

    //         });

    //         const dataForExport = Object.values(groupedData).reduce((result, group) => {
    //             return result.concat(...group);
    //         }, []);

    //         const ws = XLSX.utils.json_to_sheet(dataForExport);
    //         const wb = XLSX.utils.book_new();
    //         XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    //         XLSX.writeFile(wb, 'iCorp_CourseReport[LMS].xlsx');
    //     } else {
    //         console.log('No data available for export');
    //     }
    // };

    const exportToExcel = () => {
        // const filteredCourses = userCourses?.courses.filter((course: any) => {
        //     console.log(course);

        //     return (
        //         allCourseName.some((courseName) => {
        //             return (
        //                 course?.course?.name === courseName &&
        //                 !course?.user?.deletedBy &&
        //                 course?.user
        //             );
        //         }) &&
        //         (!searchVal || course?.course?.name.toLowerCase().includes(searchVal.toLowerCase())) &&
        //         (!searchCourseVal || course?.course?.name.toLowerCase().includes(searchCourseVal.toLowerCase())) &&
        //         (!searchUserVal || course.user?.firstName.toLowerCase() + ' ' + course.user?.lastName.toLowerCase() === searchUserVal.toLowerCase())
        //     );
        // });

        const filteredCourses = userCourses?.courses.filter((course: any) => {
            return (
                allCourseName.some((courseName) => {
                    return (
                        course?.course?.name === courseName &&
                        !course?.user?.deletedBy &&
                        course?.user
                    );
                }) &&
                // course.status !== 'Recompletion' && 
                (!searchVal || course?.course?.name.toLowerCase().includes(searchVal.toLowerCase()) || course.user?.firstName.toLowerCase().includes(searchVal?.toLowerCase()) || course.user?.lastName.toLowerCase().includes(searchVal?.toLowerCase())) &&
                (!searchCourseVal || course?.course?.name.toLowerCase().includes(searchCourseVal.toLowerCase())) &&
                (!searchUserVal || course.user?.firstName.toLowerCase() + ' ' + course.user?.lastName.toLowerCase() === searchUserVal.toLowerCase())
            );
        });


        // if (filteredCourses && filteredCourses.length > 0) {
        //     const groupedData: { [key: string]: { [key: string]: any[] } } = {};

        //     filteredCourses.forEach((course: any) => {

        //         const courseName = course.course.name;
        //         const status = course.status;

        //         if (!groupedData[courseName]) {
        //             groupedData[courseName] = {};
        //         }

        //         if (!groupedData[courseName][status]) {
        //             groupedData[courseName][status] = [];
        //         }

        //         groupedData[courseName][status].push({
        //             Username: course.user?.firstName + ' ' + course.user?.lastName,
        //             Status: status,
        //             Score: course.score ? `${course.score}%` : '-',
        //             Completed_Date: course.completedAt ? moment(course.completedAt).format('DD/MM/YYYY') : '-',
        //             Due_Date: course.dueAt ? moment(course.dueAt).format('DD/MM/YYYY') : '-',
        //         });
        //     });

        //     const statusOrder = ['Completed', 'In Progress', 'Not Yet Started'];

        //     const workbook = new ExcelJS.Workbook();
        //     const worksheet = workbook.addWorksheet('Sheet 1');

        //     const dataForExport = Object.entries(groupedData).flatMap(([courseName, statusGroups]) => {
        //         const rows = [];

        //         const sortedStatusGroups = Object.keys(statusGroups).sort((a, b) => {
        //             return statusOrder.indexOf(a) - statusOrder.indexOf(b);
        //         });

        //         for (const status of sortedStatusGroups) {
        //             const group = statusGroups[status];
        //             const isFirstStatus = sortedStatusGroups.indexOf(status) === 0;

        //             rows.push(
        //                 {
        //                     Course_Name: isFirstStatus ? courseName : '',
        //                 },
        //                 ...group
        //             );
        //         }

        //         return rows;
        //     }).filter(row => row.Course_Name !== '');

        //     worksheet.columns = [
        //         { header: 'Course Name', key: 'Course_Name', width: 20 },
        //         { header: 'Username', key: 'Username', width: 20 },
        //         { header: 'Status', key: 'Status', width: 20 },
        //         { header: 'Score', key: 'Score', width: 15 },
        //         { header: 'Completed Date', key: 'Completed_Date', width: 20 },
        //         { header: 'Due Date', key: 'Due_Date', width: 20 },
        //     ];

        //     dataForExport.forEach((row) => {
        //         const isCourseNameRow = row.Course_Name !== '';

        //         const worksheetRow = worksheet.addRow(row);

        //         if (isCourseNameRow) {
        //             worksheetRow.getCell('Course_Name').font = { bold: true };
        //         }
        //     });

        //     workbook.xlsx.writeBuffer().then((buffer) => {
        //         const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        //         const downloadLink = document.createElement('a');
        //         downloadLink.href = URL.createObjectURL(blob);
        //         downloadLink.download = 'iCorp_CourseReport[LMS].xlsx';

        //         document.body.appendChild(downloadLink);
        //         downloadLink.click();

        //         // Clean up
        //         document.body.removeChild(downloadLink);
        //     });
        // } else {
        //     console.log('No data available for export');
        // }

        if (filteredCourses && filteredCourses.length > 0) {
            const groupedData: { [key: string]: { [key: string]: any[] } } = {};
            const recompletionData: { [key: string]: { [key: string]: any[] } } = {};

            filteredCourses.forEach((course: any) => {
                const courseName = course.course.name;
                const status = course.status;

                if (!groupedData[courseName]) {
                    groupedData[courseName] = {};
                }

                if (!groupedData[courseName][status]) {
                    groupedData[courseName][status] = [];
                }

                if (!recompletionData[courseName]) {
                    recompletionData[courseName] = {};
                }

                if (!recompletionData[courseName][status]) {
                    recompletionData[courseName][status] = [];
                }

                groupedData[courseName][status].push({
                    User_Id: course?.user?._id,
                    Username: course.user?.firstName + ' ' + course.user?.lastName,
                    Status: status,
                    Score: course.score ? `${course.score}%` : '-',
                    User_Craeted_Date: course.user?.createdAt ? moment(course.user?.createdAt).format('DD/MM/YYYY') : '-',
                    Enrolment_Date: course.createdAt ? moment(course.createdAt).format('DD/MM/YYYY') : '-',
                    Start_Date: course.startAt ? moment(course.startAt).format('DD/MM/YYYY') : '-',
                    Completed_Date: course.completedAt ? moment(course.completedAt).format('DD/MM/YYYY') : '-',
                    Due_Date: course.dueAt ? moment(course.dueAt).format('DD/MM/YYYY') : '-',
                });

                if (status === 'Recompletion') {
                    recompletionData[courseName][status].push({
                        Username: course.user?.firstName + ' ' + course.user?.lastName,
                        Due_Date: course.dueAt ? moment(course.dueAt).format('DD/MM/YYYY') : '-',
                    });
                }
            });

            const statusOrder = ['Completed', 'In Progress', 'Not Yet Started', 'Recompletion'];

            const dataForExport = Object.entries(groupedData).flatMap(([courseName, statusGroups]) => {
                const rows = [];

                const sortedStatusGroups = Object.keys(statusGroups).sort((a, b) => {
                    return statusOrder.indexOf(a) - statusOrder.indexOf(b);
                });

                for (const status of sortedStatusGroups) {
                    if (status !== 'Recompletion') {
                        const group = statusGroups[status];
                        const isFirstStatus = sortedStatusGroups.indexOf(status) === 0;

                        rows.push(
                            {
                                Course_Name: isFirstStatus ? courseName : '',
                            },
                            ...group
                        );
                    }
                }

                return rows;
            }).filter(row => row.Course_Name !== '');

            const dataForExportRecompletion = Object.entries(recompletionData).flatMap(([courseName, statusGroups]) => {
                if (statusGroups['Recompletion']) {
                    const rows = [];

                    const sortedStatusGroups = Object.keys(statusGroups).sort((a, b) => {
                        return statusOrder.indexOf(a) - statusOrder.indexOf(b);
                    });

                    for (const status of sortedStatusGroups) {
                        const group = statusGroups[status];
                        const isFirstStatus = sortedStatusGroups.indexOf(status) === 0;

                        rows.push(
                            {
                                Course_Name: isFirstStatus ? courseName : '',
                            },
                            ...group
                        );
                    }

                    return rows;
                } else {
                    return [];
                }
            }).filter(row => row.Course_Name !== '');
            
            const workbook = new ExcelJS.Workbook();
            const mainWorksheet = workbook.addWorksheet('Course Report Sheet');

            const mainColumns = [
                { header: 'Course Name', key: 'Course_Name', width: 20 },
                { header: 'User Id', key: 'User_Id', width: 20 },
                { header: 'Username', key: 'Username', width: 20 },
                { header: 'Status', key: 'Status', width: 20 },
                { header: 'Score', key: 'Score', width: 15 },
                { header: 'User_Craeted_Date', key: 'User_Craeted_Date', width: 20 },
                { header: 'Enrolment Date', key: 'Enrolment_Date', width: 20 },
                { header: 'Start Date', key: 'Start_Date', width: 20 },
                { header: 'Completed Date', key: 'Completed_Date', width: 20 },
                { header: 'Due Date', key: 'Due_Date', width: 20 },
            ];

            mainWorksheet.columns = mainColumns;

            // const populateWorksheet = (worksheet: any, data: any) => {
            //     data.forEach((row: any) => {
            //         worksheet.addRow(row);
            //     });
            // };

            const populateWorksheet = (worksheet: any, data: any) => {
                data.forEach((row: any) => {
                    const worksheetRow = worksheet.addRow(row);
            
                    // Check if the row represents a Course Name (is bold)
                    if (row.Course_Name !== '') {
                        // Make the Course Name cell bold
                        mainColumns.forEach((column) => {
                            if (column.key === 'Course_Name') {
                                worksheetRow.getCell(column.key).font = { bold: true };
                            }
                        });
                    }
                });
            };

            populateWorksheet(mainWorksheet, dataForExport);

            // Check if there is data for the "Recompletion" sheet
            if (dataForExportRecompletion.length > 0) {
                const recompletionWorksheet = workbook.addWorksheet('Recompletion Sheet');
                const recompletionColumns = [
                    { header: 'Course Name', key: 'Course_Name', width: 20 },
                    { header: 'Username', key: 'Username', width: 20 },
                    { header: 'Due Date', key: 'Due_Date', width: 20 },
                ];

                recompletionWorksheet.columns = recompletionColumns;
                populateWorksheet(recompletionWorksheet, dataForExportRecompletion);
            }

            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = 'iCorp_CourseReport[LMS].xlsx';

                document.body.appendChild(downloadLink);
                downloadLink.click();

                // Clean up
                document.body.removeChild(downloadLink);
            });

        } else {
            console.log('No data available for export');
        }

    };


    // const exportToExcel = () => {
    //     const filteredCourses = userCourses?.courses.filter((course: any) => {
    //         return allCourseName.some((courseName) => {
    //             return course?.course?.name === courseName && !course?.user?.deletedBy && course?.user;
    //         });
    //     });

    //     if (filteredCourses && filteredCourses.length > 0) {
    //         const groupedData: { [key: string]: { [key: string]: any[] } } = {};

    //         filteredCourses.forEach((course: any) => {
    //             const courseName = course.course.name;
    //             const status = course.status;

    //             if (!groupedData[courseName]) {
    //                 groupedData[courseName] = {};
    //             }

    //             if (!groupedData[courseName][status]) {
    //                 groupedData[courseName][status] = [];
    //             }

    //             groupedData[courseName][status].push({
    //                 Username: course.user?.firstName + ' ' + course.user?.lastName,
    //                 Status: status,
    //                 Score: course.score ? `${course.score}%` : '-',
    //                 Completed_Date: course.completedAt ? moment(course.completedAt).format('DD/MM/YYYY') : '-',
    //                 Due_Date: course.dueAt ? moment(course.dueAt).format('DD/MM/YYYY') : '-',
    //             });
    //         });

    //         const statusOrder = ['Completed', 'In Progress', 'Not Yet Started'];

    //         const dataForExport = Object.entries(groupedData).flatMap(([courseName, statusGroups]) => {
    //             const rows = [];

    //             const sortedStatusGroups = Object.keys(statusGroups).sort((a, b) => {
    //                 return statusOrder.indexOf(a) - statusOrder.indexOf(b);
    //             });

    //             for (const status of sortedStatusGroups) {
    //                 const group = statusGroups[status];
    //                 const isFirstStatus = sortedStatusGroups.indexOf(status) === 0;

    //                 rows.push(
    //                     {
    //                         Course_Name: isFirstStatus ? courseName : '',
    //                     },
    //                     ...group
    //                 );
    //             }

    //             return rows;
    //         }).filter(row => row.Course_Name !== ''); 

    //         const ws = XLSX.utils.json_to_sheet(dataForExport);
    //         const wb = XLSX.utils.book_new();
    //         XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    //         XLSX.writeFile(wb, 'iCorp_CourseReport[LMS].xlsx');
    //     } else {
    //         console.log('No data available for export');
    //     }
    // };


    return (
        <Grid>
            <Typography variant="h4" mb={3}>
                {textConst.report}
            </Typography>

            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{
                    width: {
                        xs: '80%',
                        sm: '20%'
                    },
                    display: 'flex', alignItems: 'center', mr: 4, mb: 4
                }}>
                    <SearchBarReport
                        placeHolder="Search"
                        size="small"
                        onKeyUp={(value: string) => {
                            setSearchVal?.(value.trim());
                        }}
                        handleClose={() => setSearchVal('')}
                    />
                </Box>

                <Grid item xs={12} sm={3} md={2}>
                    <HookAutoComplete
                        sx={{ width: '20%', mr: 4 }}
                        name="course"
                        label={'Course'}
                        control={control}
                        options={courseList}
                        size={'small'}
                        getOptionLabel="name"
                        isRequired={false}
                        multiple={false}
                        onChangeVal={(val: any) => {
                            setSearchCourseVal(val?.name);
                        }}
                    />
                </Grid>

                <HookAutoComplete
                    sx={{ width: '20%' }}
                    name="user"
                    label={'User'}
                    control={control}
                    options={userList}
                    size={'small'}
                    getOptionLabel="name"
                    isRequired={false}
                    multiple={false}
                    onChangeVal={(val: any) => {
                        setSearchUserVal(val?.name);
                    }}
                />

                <Box
                    minWidth={'150px'}
                    width={{ sm: '200px', xs: '100%', md: '180px' }}
                    pl={{ sm: 2, xs: 2 }}
                >
                    <Button
                        fullWidth
                        sx={{ py: 1 }}
                        variant="contained"
                        startIcon={<DownloadIcon />}
                        size="medium"
                        onClick={exportToExcel}
                    >
                        {textConst.export + ' ' + textConst.reports}
                    </Button>
                </Box>
            </Box> */}

            <Grid
                container
                item
                xs={12}
                lg={12}
                xl={12}
                spacing={2}
                justifyContent='flex-end'
                alignItems={'center'}
                sx={{ mb: 4 }}
            >
                <Grid item xs={12} sm={4} md={3}>
                    <SearchBarReport
                        placeHolder="Search"
                        size="small"
                        onKeyUp={(value: string) => {
                            setSearchVal?.(value.trim());
                        }}
                        handleClose={() => setSearchVal('')}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3.5}>
                    <HookAutoComplete
                        name="course"
                        label={'Course'}
                        control={control}
                        options={courseList}
                        size={'small'}
                        getOptionLabel="name"
                        isRequired={false}
                        multiple={false}
                        onChangeVal={(val: any) => {
                            setSearchCourseVal(val?.name);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3.5}>
                    <HookAutoComplete
                        name="user"
                        label={'User'}
                        control={control}
                        options={userList}
                        size={'small'}
                        getOptionLabel="name"
                        isRequired={false}
                        multiple={false}
                        onChangeVal={(val: any) => {
                            setSearchUserVal(val?.name);
                        }}
                    />
                </Grid>

                <Box
                    minWidth={'150px'}
                    width={{ sm: '200px', xs: '100%', md: '180px' }}
                    pl={{ sm: 2, xs: 2 }}
                    pt={{ xs: 2 }}
                >
                    <Button
                        fullWidth
                        sx={{ py: 1 }}
                        variant="contained"
                        startIcon={<DownloadIcon />}
                        size="medium"
                        onClick={exportToExcel}
                    >
                        {textConst.export + ' ' + textConst.reports}
                    </Button>
                </Box>

            </Grid>


            <Grid container gap={'24px'} sx={{
                display: 'flex', justifyContent: {
                    xs: 'center', lg: 'center', md: 'center', xl: 'flex-start', sm: 'center'
                }
            }}>
                {user?.role !== 'Admin' ? (
                    <Grid container spacing={'24px'}>
                        <Grid item xs={12} lg={6}>
                            <CommonTableUser
                                rows={courseData?.courses}
                                columns={homeCourseColumns}
                                page={0}
                                rowsPerPage={5}
                                onPageChange={() => { }}
                                onRowsPerPageChange={() => { }}
                                totalCount={courseData?.courses?.length}
                                isSearching={isFetching}
                                isPagination={false}
                                handleClick={(e, row) => {
                                    navigate(`/learning/course/${row?.course?._id}`);
                                }}
                                sx={{
                                    '&:last-child td': {
                                        borderBottom: 0,
                                    },
                                }}
                                sortMethod={(column: any) => {
                                    setSortField(column?.field);
                                    setSortOrder((prevSortOrder) =>
                                        column?.field === sortField && prevSortOrder === 'asc'
                                            ? 'desc'
                                            : 'asc'
                                    );
                                }}
                                sortColumn={sortField}
                                sortOrder={sortOrder}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            sx={{
                                borderRadius: '16px',
                                overflow: 'hidden',
                            }}
                        >
                            <Box
                                sx={{
                                    borderRadius: '16px',
                                    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        borderTopRightRadius: '16px',
                                        borderTopLeftRadius: '16px',
                                        p: '12px 16px',
                                        backgroundColor: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: (theme) => theme.palette.grey[50],
                                            fontWeight: 500,
                                        }}
                                        variant="subtitle2"
                                    >
                                        {textConst.report}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        borderBottomRightRadius: '16px',
                                        borderBottomLeftRadius: '16px',
                                        py: 3,
                                        backgroundColor: (theme) => theme.palette.grey[50],
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {courseData?.courses?.length ? (
                                        <PieChart chartData={convertChartData(courseData?.courses)} />
                                    ) : (
                                        <Typography sx={{ fontSize: 14 }}>
                                            No data available
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                ) : null}

                {userCoursesLoading ? <Loader /> : user?.role === 'Admin' ? (
                    <>
                        {allCourseName.map((courseName) => (
                            <PieChartForCourseUser key={courseName} userCourses={userCourses} targetCourseName={courseName} searchVal={searchVal} setSearchVal={setSearchVal}
                                searchUserVal={searchUserVal} searchCourseVal={searchCourseVal}
                            />
                        ))}
                    </>
                ) : null}

            </Grid>
        </Grid>
    );
};

export default Report;
