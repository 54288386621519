import { createSlice } from '@reduxjs/toolkit';

type UserInfo = {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  image: any;
  summary: string;
  isActive: boolean;
  isDirectStaff: boolean;
  mobileNumber: number;
  role: 'Manager' | 'Admin' | 'User';
  iat: number;
  exp: number;
  aud: string;
};

type User = {
  info: UserInfo;
  selectedCourse: any[];
  allCourses: any[];
};

type InitState = {
  user: User;
};

const initialState = {
  selectedCourse: [],
  allCourses: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state: any, action) => {
      state.info = action.payload;
      return state;
    },
    updateUserInfo: (state: any, action) => {
      state.info = { ...state.info, ...action.payload };
      return state;
    },
    setSelectedCourse: (state: any, action) => {
      state.selectedCourse = [
        ...state.selectedCourse,
        ...action.payload,
      ]?.filter((item: any, index: any, self: any) => {
        return index === self.findIndex((t: any) => t?._id === item?._id);
      });
      return state;
    },
    removeSelectedCourse: (state: any, action) => {
      state.selectedCourse = state.selectedCourse.filter(
        (item: any) => item._id !== action.payload._id
      );
      return state;
    },
    filterSelectedByCategoryIds: (state, action) => {
      const categoryIds = action.payload;

      const selectedFilter = JSON.parse(
        JSON.stringify(state.selectedCourse)
      ).filter((item: any) => {
        const courseCategoryIds = item.categoryIds?.map(
          (item: any) => item._id
        );
        return courseCategoryIds.find((element: any) =>
          categoryIds.includes(element)
        );
      });
      state.selectedCourse = selectedFilter;
      return state;
    },
    addToAllCourses: (state: any, action) => {
      state.allCourses = [...state.allCourses, ...action.payload]?.filter(
        (item: any, index: any, self: any) => {
          return index === self.findIndex((t: any) => t?._id === item?._id);
        }
      );
      return state;
    },
    removeFromAllCourses: (state: any, action) => {
      const remainingIds = action.payload?.map((item: any) => item._id);

      state.allCourses = state.allCourses.filter((item: any) =>
        item.categoryIds.find((catItem: any) => remainingIds.includes(catItem))
      );
      return state;
    },
    removeAllCourses: (state: any) => {
      state.allCourses = [];
      state.selectedCourse = [];
      return state;
    },
  },
});

export const {
  setUserInfo,
  updateUserInfo,
  setSelectedCourse,
  filterSelectedByCategoryIds,
  removeSelectedCourse,
  addToAllCourses,
  removeFromAllCourses,
  removeAllCourses,
} = userSlice.actions;

export const userSelector = (state: InitState) => state.user.info;

export const selectedCourseSelector = (state: InitState) =>
  state.user.selectedCourse;
export const allCoursesSelector = (state: InitState) => state.user.allCourses;

export default userSlice.reducer;
