import * as React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
// eslint-disable-next-line import/named
import { TransitionProps } from '@mui/material/transitions';

import HookTextShowSummary from './HookTextShowSummary';
import textConst from '../utils/textConst';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type AlertDialogProps = {
    open: boolean;
    title: string;
    content: string;
    saveBtnText?: string;
    customColor?: any;
    handleClose: React.MouseEventHandler<HTMLButtonElement> | undefined;
    selectedProfile?: any;
    selectedEditSummaryIdRecord?: any;
    selectedEditIdRecord?: any;
    isLoading?: boolean;
    [key: string]: any;
};

export default function AlertShowSummaryDialog({
    open,
    title,
    content,
    saveBtnText,
    customColor,
    selectedProfile,
    handleClose,
    selectedEditIdRecord,
    selectedEditSummaryIdRecord,
    isLoading,
    ...rest
}: AlertDialogProps) {

    return (
        <Dialog
            {...rest}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            sx={{
                '& .MuiPaper-root': {
                    overflow: 'visible',
                    borderRadius: '22px 22px 20px 20px',
                    maxWidth: {
                        lg: '100%'
                    },
                    width: {
                        xs: '100%',
                        sm: '100%',
                        md: '50%',
                        lg: '50%'
                    },
                },
            }}
        >
            {/* <DialogTitle>{title}</DialogTitle> */}
            <DialogContent sx={{ p: 0, borderRadius: '20px 20px 0 0' }}>
                <DialogContentText>
                    <HookTextShowSummary
                        rows={selectedProfile?.role?.name === 'Admin' ? undefined : 4}
                        selectedProfile={selectedProfile}
                        userSummaryData={content}
                        selectedEditIdRecord={selectedEditIdRecord}
                        selectedEditSummaryIdRecord={selectedEditSummaryIdRecord}
                        handleClose={handleClose}
                    />
                </DialogContentText>
            </DialogContent>
            {/* <DialogActions sx={{ px: 3, py: 2 }}>
                <Button variant="outlined" color="secondary" onClick={handleClose}>
                    {textConst.cancel}
                </Button>
            </DialogActions> */}
        </Dialog>
    );
}
