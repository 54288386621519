import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { btnIconMap, chartColors, iconMap, slideDuration } from './constant';

type TypeOptions = 'success' | 'error' | 'warning' | 'info';

type Role = {
  _id: string;
  name: string;
};

export const jwtDecode = (t: string): any => {
  if (t.includes('.')) {
    const token: any = {};
    token.raw = t;
    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return token;
  }
  return t;
};

export const setCookie = (cname: string, cvalue: string): void => {
  if (cvalue) {
    const jwtData = jwtDecode(cvalue);
    const d = jwtData.payload?.exp
      ? new Date(jwtData.payload.exp * 1000).toUTCString()
      : new Date(new Date().getTime() + 60 * 60 * 1000).toUTCString();
    const expires = 'expires=' + d;
    document.cookie =
      cname + '=' + btoa(JSON.stringify(cvalue)) + ';' + expires + ';path=/';
  }
};

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0 && name.length !== c.length) {
      return atob(c.substring(name.length, c.length));
    }
  }
  return '';
};

export const deleteCookie = (cname: string) => {
  document.cookie = cname + '=; Path=/;max-age=0';
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    deleteCookie(name);
  }
};

export const queryParamsBuilder = (query: any): string => {
  if (typeof query !== 'object') {
    return '';
  }
  const keys = Object.keys(query).filter(
    (b) => query[b] !== null && query[b] !== ''
  ) as unknown as Record<string, any>[];
  if (keys.length) {
    return (
      '?' +
      new URLSearchParams(
        keys.reduce((a, b: any) => {
          a[b] = query[b];
          return a;
        }, {} as Record<string, any>)
      ).toString()
    );
  }
  return '';
};

export function updateCacheData(
  { state, method, dispatch, extendedApi }: any,
  cb: any
): void {
  const { api }: any = state;

  let cacheArgToUpdate: any[] = [undefined];
  if (api) {
    cacheArgToUpdate = Object.keys(api.queries)
      .filter(
        (d: string) =>
          d.indexOf(`${method}(`) === 0 &&
          Object.keys(api.queries[d]).length > 0
      )
      ?.map((d: string) => api.queries[d].originalArgs);
  }
  cacheArgToUpdate.forEach((originalArgs: any) =>
    dispatch(extendedApi.util.updateQueryData(method, originalArgs, cb))
  );
}

export const encryptField = (field: string) => {
  return CryptoJS.AES.encrypt(field, '4pvr78fybt3kqj').toString();
};

export const decryptField = (field: string) => {
  return CryptoJS.AES.decrypt(field, '4pvr78fybt3kqj').toString(
    CryptoJS.enc.Utf8
  );
};

export const notify = (
  message: string,
  type: TypeOptions,
  autoClose?: number,
  toastId?: string
) =>
  toast.success(
    message?.toLowerCase()?.includes('internal server error')
      ? 'Something went wrong'
      : message,
    {
      toastId: toastId || uuidv4(),
      position: 'top-right',
      autoClose: autoClose || 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      type: type,
    }
  );
export const getRole = (arr: Role[], id: string) => {
  const role: Role | undefined = arr.find((role: Role) => role._id === id);
  if (role) {
    return role.name;
  }
  return null;
};

export const capitalizeFirstLetter = (name: string) => {
  return name?.charAt(0).toUpperCase() + name?.slice(1);
};

export const getFullName = (arr: []) => {
  return arr?.map(
    (item: any) =>
      `${capitalizeFirstLetter(item.firstName)} ${capitalizeFirstLetter(
        item.lastName
      )}`
  );
};

export const getIsLastWatchedVideo = (slideData: any, currentSlide: any) => {
  const typeArray: any[] = Array.from(
    { length: slideDuration?.slide },
    (_, index) => slideData?.[index]?.type
  ).reverse();
  const lastVideoIndex =
    typeArray.length -
    1 -
    typeArray?.findIndex((item: any) => item === 'video');
  return currentSlide === lastVideoIndex;
};

export const convertChartData = (mainData: any) => {
  if (!Array.isArray(mainData)) {
    return [];
  }

  const statusCounts = mainData?.reduce((counts: any, entry: any) => {
    const status = entry.status;
    counts[status] = (counts[status] || 0) + 1;
    return counts;
  }, {});

  const outputData = mainData?.map((entry: any) => {
    const status = entry.status;
    const count = statusCounts[status];
    const color = chartColors[status];
    const icon = iconMap[status];
    const btnIcon = btnIconMap[status];
    const btnName =
      entry.status === 'Not Yet Started'
        ? 'Start'
        : entry.status === 'In Progress'
        ? 'Resume'
        : entry.status === 'Completed'
        ? 'View'
        : 'Start';

    return {
      text: status,
      value: count,
      color,
      icon,
      btnName,
      btnIcon,
    };
  });

  const uniqueEntries = outputData.filter(
    (entry: any, index: number, self: any) => {
      return (
        index ===
        self?.findIndex(
          (item: any) => item.text === entry.text && item.value === entry.value
        )
      );
    }
  );

  return uniqueEntries;
};

export const getGeneratedOptions = (
  optionLength?: number,
  startingIndex?: number
) => {
  const optionLengthClone = optionLength || 8;
  const startingIndexClone = startingIndex || 1;
  return Array.from({ length: optionLengthClone - 1 }, (_, index) => {
    return {
      _id: index + startingIndexClone + 1,
      name: `${index + startingIndexClone + 1}`,
    };
  });
};

export const uniqueArray = (array: any[]) => {
  return array.filter(
    (value: any, index: any, self: any) => self.indexOf(value) === index
  );
};
