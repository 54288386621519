import { Navigate, useRoutes } from 'react-router-dom';
import ReportCourse from './course/ReportCourse';
import Report from './userCourse/Report';


const ReportRouting = () => {

    const defaultNavigate = <Navigate to={'/report/course'} />;

    const routes = [
        {
            path: '/',
            children: [
                {
                    index: true,
                    element: defaultNavigate,
                },
                {
                    path: '/course',
                    element: <ReportCourse />,
                },
                {
                    path: '/user-course',
                    element: <Report />,
                },
            ],
        },
    ];
    return useRoutes(routes);
};

export default ReportRouting;
