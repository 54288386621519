import React from 'react';

import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useController } from 'react-hook-form';

interface HookTextFieldProps {
  name: string;
  control: any;
  label: string;
  isRequired?: boolean;
  size?: any;
  defaultValue?: string;
  [key: string]: any;
  customError?: boolean;
}

const HookTextField: React.FC<HookTextFieldProps> = ({
  name,
  control,
  label,
  isRequired = true,
  size,
  defaultValue = '',
  customError = false,
  ...rest
}) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    defaultValue,
  });
  return (
    <>
      <TextField
        {...field}
        size={size ? size : 'medium'}
        label={
          <Stack direction="row" gap={0.5}>
            {label}
            {isRequired && (
              <Typography sx={{ color: (theme) => theme.palette.primary.dark }}>
                *
              </Typography>
            )}
          </Stack>
        }
        fullWidth
        error={invalid && !customError}
        {...rest}
        sx={{
          '& .MuiInputLabel-outlined': {
            color: (theme) => theme.palette.grey[300],
          },
          '& label.Mui-focused': {
            color: (theme) =>
              invalid ? theme.palette.primary.main : theme.palette.grey[400],
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: (theme) => theme.palette.grey[200],
            },
            '&:hover fieldset': {
              borderColor: (theme) => theme.palette.grey[300],
            },
            '&.Mui-focused fieldset': {
              borderColor: (theme) =>
                invalid ? theme.palette.primary.main : theme.palette.grey[400],
            },
            '& .Mui-disabled': {
              backgroundColor: (theme) => theme.palette.grey[100],
              WebkitTextFillColor: (theme) => theme.palette.grey[500],
            },
          },
        }}
      />
      {invalid && !customError && (
        <FormHelperText
          sx={{
            color: (theme) => theme.palette.primary.dark,
            m: '3px 14px 0px',
          }}
        >
          {error?.message}
        </FormHelperText>
      )}
    </>
  );
};

export default HookTextField;
