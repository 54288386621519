import React, { useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
// eslint-disable-next-line import/named
import { TransitionProps } from '@mui/material/transitions';

import LoadingButton from './LoadingButton';
import textConst from '../utils/textConst';
import { Grid } from '@mui/material';
import HookTextField from './HookTextfield';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import validationSchemas from '../utils/validationSchemas';
import { useUpdateCourseSettingsMutation } from '../store/apis/setting';
import { notify } from '../utils/commonFunctions';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type AlertDialogProps = {
    open: boolean;
    title: string;
    content: any;
    courseEditId: any;
    saveBtnText?: string;
    customColor?: any;
    handleClose: () => void;
    refetch: any;
    // handleClose: React.MouseEventHandler<HTMLButtonElement> | undefined;
    //   handleSave: React.MouseEventHandler<HTMLButtonElement> | undefined;
    //   isLoading?: boolean;
    [key: string]: any;
};

const validationSchema = yup.object({
    assessmentPassPercentage: validationSchemas.assessmentPassPercentage,
});

export default function AlertSettingDialog({
    open,
    title,
    content,
    courseEditId,
    saveBtnText,
    customColor,
    handleClose,
    refetch,
    handleSave,
    isLoading,
    ...rest
}: AlertDialogProps) {

    const { handleSubmit, control, setValue, reset } = useForm({
        defaultValues: {
            assessmentPassPercentage: content,
        },
        resolver: yupResolver(validationSchema),
        mode: 'onTouched',
        reValidateMode: 'onChange',
    });

    const [
        updateCourseSettings,
        {
            isSuccess: isUpdateSuccessSetting,
            isError: isUpdateError,
            error: updateError,
        },
    ] = useUpdateCourseSettingsMutation();

    const onSubmit = (formData: any) => {
        const assessmentPercentageValue = formData.assessmentPassPercentage;

        const bodyData = {
            assessmentPassingPer: {
                value: assessmentPercentageValue
            }
        }
        updateCourseSettings({ id: courseEditId, ...bodyData });
        notify('Course Setting updated successfully', 'success', 1200);
    }

    useEffect(() => {
        if (isUpdateSuccessSetting) {
            refetch();
            handleClose();
            reset();
            setValue('assessmentPassPercentage', '');
        }
    }, [isUpdateSuccessSetting, reset, setValue, handleClose, refetch]);

    useEffect(() => {
        if (isUpdateError) {
            notify(updateError?.data?.message, 'error', 1200);
        }
    }, [isUpdateError, updateError]);


    return (
        <Dialog
            {...rest}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            sx={{
                '& .MuiPaper-root': {
                    overflow: 'visible',
                    maxWidth: {
                        lg: '100%'
                    },
                    width: {
                        xs: '100%',
                        sm: '100%',
                        md: '50%',
                        lg: '50%'
                    },
                },
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            <form>
                <DialogContent>
                    {/* <DialogContentText>{content}</DialogContentText> */}
                    <Grid container spacing={3} sx={{ py: 2 }}>
                        <Grid item xs={8}>
                            <HookTextField
                                control={control}
                                type="text"
                                name="assessmentPassPercentage"
                                label="Assessment Pass Percentage"
                                inputProps={{ maxLength: 3 }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Button variant="outlined" color="secondary" onClick={handleClose}>
                        {textConst.cancel}
                    </Button>
                    {saveBtnText && (
                        <LoadingButton
                            isLoading={!!isLoading}
                            onClick={handleSubmit(onSubmit)}
                            variant="outlined"
                            color={customColor ? 'secondary' : 'inherit'}
                            sx={{
                                minWidth: '85px',
                                color: (theme: any) => customColor || theme.palette.primary.dark,
                            }}
                        >
                            {saveBtnText}
                        </LoadingButton>

                    )}
                </DialogActions>
            </form>

        </Dialog>
    );
}
