// import React, { useState } from 'react';

// import { Editor } from '@tinymce/tinymce-react';

// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import Loader from './Loader';

// const MyEditor = ({ handleChange, value, initialValue }: any) => {
//   const [loading, setLoading] = useState(true);

//   return (
//     <>
//       {loading && <Loader />}
//       <Editor
//         // apiKey={process.env.EDITOR_API_KEY}
//         apiKey="2o46z04mlu84tqawwqff9pkfrcicyqsyra8w9ud06161kfue"
//         onInit={() => {
//           setLoading(false);
//         }}
//         cloudChannel="5-stable"
//         disabled={false}
//         initialValue={initialValue}
//         inline={false}
//         onEditorChange={(val: any) => {
//           handleChange?.(val);
//         }}
//         plugins=""
//         tagName="div"
//         textareaName=""
//         value={value}
//         init={{
//           height: 300,
//           min_height: 300,
//           language: 'en',
//           branding: false,
//           menubar: false,
//           statusbar: false,
//           plugins: [
//             'advlist autolink lists link image charmap print preview anchor',
//             'searchreplace visualblocks code fullscreen',
//             'insertdatetime media table paste code help wordcount',
//           ],
//           toolbar:
//             'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
//         }}
//       />
//     </>
//   );
// };

// export default MyEditor;

import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loader from './Loader';

const MyEditor = ({ handleChange, value, initialValue, disabled }: any) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <Loader />}
      <Editor
        apiKey="2o46z04mlu84tqawwqff9pkfrcicyqsyra8w9ud06161kfue"
        onInit={() => {
          setLoading(false);
        }}
        cloudChannel="5-stable"
        disabled={disabled}
        initialValue={initialValue}
        inline={false}
        onEditorChange={(val: any) => {
          if (val.length <= 250) {
            handleChange?.(val);
          } 
        }}
        plugins=""
        tagName="div"
        textareaName=""
        value={value}
        init={{
          height: 300,
          min_height: 300,
          language: 'en',
          branding: false,
          menubar: false,
          statusbar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
        }}
      />
    </>
  );
};

export default MyEditor;



// import React, { useState } from 'react';
// import { Editor } from '@tinymce/tinymce-react';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import Loader from './Loader';

// const MyEditor = ({ handleChange, value, initialValue, disabled }: any) => {
//   const [loading, setLoading] = useState(true);

//   return (
//     <>
//       {loading && <Loader />}
//       <Editor
//         apiKey="2o46z04mlu84tqawwqff9pkfrcicyqsyra8w9ud06161kfue"
//         onInit={() => {
//           setLoading(false);
//         }}
//         cloudChannel="5-stable"
//         disabled={disabled} // Use the disabled prop received from the parent
//         initialValue={initialValue}
//         inline={false}
//         onEditorChange={(val: any) => {
//           handleChange?.(val);
//         }}
//         plugins=""
//         tagName="div"
//         textareaName=""
//         value={value}
//         init={{
//           height: 300,
//           min_height: 300,
//           language: 'en',
//           branding: false,
//           menubar: false,
//           statusbar: false,
//           plugins: [
//             'advlist autolink lists link image charmap print preview anchor',
//             'searchreplace visualblocks code fullscreen',
//             'insertdatetime media table paste code help wordcount',
//           ],
//           toolbar:
//             'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
//         }}
//       />
//     </>
//   );
// };

// export default MyEditor;



