import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import HookRadioGroupField from '../../../../components/HookRadioGroupField';
import HookTextField from '../../../../components/HookTextfield';
import Loader from '../../../../components/Loader';
import LoadingButton from '../../../../components/LoadingButton';
import {
  useAddAssessmentCategoryMutation,
  useGetAssessmentCategoryByIdQuery,
  useUpdateAssessmentCategoryMutation,
} from '../../../../store/apis/assessment';
import { notify } from '../../../../utils/commonFunctions';
import textConst from '../../../../utils/textConst';
import validationSchemas from '../../../../utils/validationSchemas';

const validationSchema = yup.object({
  categoryName: validationSchemas.categoryName,
  isActive: yup.boolean(),
});

const AddEditCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [addPost, { isSuccess, isLoading, isError, error }] =
    useAddAssessmentCategoryMutation();
  const [
    updatePost,
    {
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
    },
  ] = useUpdateAssessmentCategoryMutation();

  const {
    data: categoryData,
    isLoading: isCategoryLoading,
    isSuccess: isCategorySuccess,
    isError: isCategoryError,
    error: categoryError,
  } = useGetAssessmentCategoryByIdQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  const { handleSubmit, control, reset, watch, setValue } = useForm({
    defaultValues: {
      categoryName: '',
      isActive: true,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  });

  useEffect(() => {
    if (isCategorySuccess) {
      setValue('categoryName', categoryData?.name);
      setValue('isActive', categoryData?.isActive);
    }
  }, [categoryData?.isActive, categoryData?.name, isCategorySuccess, setValue]);

  useEffect(() => {
    if (isError || isUpdateError || isCategoryError) {
      notify(
        error?.data?.message ||
          updateError?.data?.message ||
          categoryError?.data?.message,
        'error'
      );
    }
    if (error?.status === 'FETCH_ERROR') {
      notify('Please try again later.', 'error');
    }
  }, [
    error,
    isError,
    isUpdateError,
    updateError,
    isCategoryError,
    categoryError,
  ]);

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      notify(
        `Category ${isSuccess ? 'added' : 'updated'} successfully`,
        'success',
        1200
      );
      reset();
      navigate(-1);
    }
  }, [isSuccess, isUpdateSuccess, navigate, reset]);

  const onSubmit = (data: any) => {
    if (id) {
      updatePost({
        id: id,
        name: data?.categoryName,
        isActive: data?.isActive,
      });
    } else {
      addPost({
        name: data?.categoryName,
        isActive: data?.isActive,
      });
    }
  };

  const categoryName = watch('categoryName');

  const isDisable = !categoryName;

  return isCategoryLoading ? (
    <Loader />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h4" mb={3}>
        {id ? textConst.edit : textConst.add} {textConst.category}
      </Typography>
      <Card
        sx={{
          p: 3,
          minHeight: '5%',
        }}
      >
        <Grid container spacing={3} alignItems={'center'}>
          <Grid item xs={12} md={6}>
            <HookTextField
              control={control}
              name="categoryName"
              label="Category Name"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              alignItems="center"
              justifyContent="flex-start"
              flexDirection="row"
            >
              <Typography
                marginRight={'25px'}
                color={(theme) => theme.palette.grey[500]}
              >
                {textConst.status}:
              </Typography>
              <HookRadioGroupField
                control={control}
                defaultValue="true"
                row={true}
                name="isActive"
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={
                    <Typography color={(theme) => theme.palette.grey[500]}>
                      {textConst.active}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={
                    <Typography color={(theme) => theme.palette.grey[500]}>
                      {textConst.inactive}
                    </Typography>
                  }
                />
              </HookRadioGroupField>
            </Stack>
          </Grid>
        </Grid>
      </Card>
      <Stack direction={'row'} gap={2} justifyContent={'end'} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            navigate(-1);
          }}
        >
          {textConst.cancel}
        </Button>
        <Box sx={{ cursor: 'not-allowed' }}>
          <LoadingButton
            variant="contained"
            color="secondary"
            type="submit"
            isLoading={isLoading || isUpdateLoading}
            disabled={isDisable || isLoading || isUpdateLoading}
            sx={{ minWidth: '150px' }}
          >
            {id ? textConst.save : textConst.add} {textConst.category}
          </LoadingButton>
        </Box>
      </Stack>
    </form>
  );
};

export default AddEditCategory;
