// import * as React from 'react';

// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import Slide from '@mui/material/Slide';
// // eslint-disable-next-line import/named
// import { TransitionProps } from '@mui/material/transitions';

// import LoadingButton from './LoadingButton';
// import textConst from '../utils/textConst';
// import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
// import CustomChip from './CustomChip';
// import { commonColors } from '../theme-loader/theme/color';
// import CloseIcon from '@mui/icons-material/Close';


// const Transition = React.forwardRef(function Transition(
//     props: TransitionProps & {
//         children: React.ReactElement<any, any>;
//     },
//     ref: React.Ref<unknown>
// ) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

// type AlertDialogProps = {
//     open: boolean;
//     title: string;
//     content: any;
//     saveBtnText?: string;
//     customColor?: any;
//     handleClose: React.MouseEventHandler<HTMLButtonElement> | undefined;
//     handleSave: React.MouseEventHandler<HTMLButtonElement> | undefined;
//     isLoading?: boolean;
//     [key: string]: any;
// };

// type ListType = {
//     status: 'Not Yet Started' | 'In Progress';
// };


// export default function AlertReminderDialog({
//     open,
//     userIdName,
//     checkedCourses,
//     setCheckedCourses,
//     title,
//     content,
//     saveBtnText,
//     customColor,
//     handleClose,
//     handleSave,
//     isLoading,
//     ...rest
// }: AlertDialogProps) {

//     const getStatusColor = (status: 'Not Yet Started' | 'In Progress') => {
//         const colorMapping: Record<string, string> = {
//             'Not Yet Started': commonColors.brightRed,
//             'In Progress': commonColors.orange,
//         };

//         return colorMapping[status] || commonColors.green;
//     };


//     const handleCheckboxChange = (courseId: string) => {
//         if (checkedCourses.includes(courseId)) {

//             setCheckedCourses((prevCheckedCourses: any) =>
//                 prevCheckedCourses.filter((id: any) => id !== courseId)
//             );
//         } else {
//             setCheckedCourses((prevCheckedCourses: any) => [...prevCheckedCourses, courseId]);
//         }
//     };

//     return (
//         <Dialog
//             {...rest}
//             open={open}
//             TransitionComponent={Transition}
//             keepMounted
//             onClose={handleClose}
//             sx={{
//                 '& .MuiPaper-root': {
//                     overflow: 'visible',
//                     width: {
//                         xs: '100%',
//                         sm: '30%',
//                     },
//                 },
//             }}
//         >
//             <Box
//                 sx={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     justifyContent: 'space-between',
//                     borderTopRightRadius: '3px',
//                     borderTopLeftRadius: '3px',
//                     backgroundColor: (theme) => theme.palette.grey[500],
//                 }}
//             >
//                 <DialogTitle sx={{ color: '#FFFFFF' }}>{userIdName} {title}</DialogTitle>
//                 <Button onClick={handleClose} style={{ cursor: 'pointer', background: 'none', border: 'none' }}>
//                     <CloseIcon sx={{ color: '#FFFFFF' }} />
//                 </Button>
//             </Box>

//             {content?.courses.some((list: any) => list?.status === 'Completed') && (
//                 <>
//                     <Typography
//                         sx={{
//                             color: '#000000',
//                             fontWeight: 500,
//                             mt: 2,
//                             ml: 2
//                         }}
//                     >
//                         Completed Courses
//                     </Typography>

//                     {content?.courses
//                         .filter((list: any) => list?.status === 'Completed')
//                         .map((list: any, index: any) => (
//                                 <DialogContent sx={{ p: '8px 20px', overflow: 'hidden', display: 'flex' }} key={index}>
//                                     <DialogContentText>{list.course.name}</DialogContentText>
//                                     <CustomChip
//                                     label='Completed'
//                                     color="primary"
//                                     sx={{
//                                         background: commonColors.green,
//                                         ml: 2
//                                     }}
//                                 />
//                                 </DialogContent>
//                         ))
//                     }
//                 </>
//             )}

//             <Typography
//                 sx={{
//                     color: '#000000',
//                     fontWeight: 500,
//                     mt: 2,
//                     ml: 2
//                 }}
//             >
//                 In Progress & Not yet started Courses
//             </Typography>

//             <FormGroup sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
//                 {content?.courses.map((list: any, index: any) => (
//                     list?.status !== 'Completed' && (
//                         <Box>
//                             <FormControlLabel
//                                 key={index}
//                                 label={list.course.name}
//                                 control={
//                                     <Checkbox
//                                         name="basic-unchecked"
//                                         checked={checkedCourses.includes(list.course._id)}
//                                         onChange={() => handleCheckboxChange(list.course._id)}
//                                     />
//                                 }
//                             />
//                             <CustomChip
//                                 label={list.status}
//                                 color="primary"
//                                 sx={{
//                                     background: getStatusColor(list.status),
//                                 }}
//                             />
//                         </Box>
//                     )
//                 ))}
//             </FormGroup>

//             <DialogActions sx={{ px: 3, py: 4 }}>
//                 <Button variant="outlined" color="secondary" onClick={handleClose}>
//                     {textConst.cancel}
//                 </Button>
//                 {saveBtnText && (
//                     <LoadingButton
//                         isLoading={!!isLoading}
//                         onClick={handleSave}
//                         variant="outlined"
//                         color={customColor ? 'secondary' : 'inherit'}
//                         sx={{
//                             minWidth: '85px',
//                             color: (theme: any) => customColor || theme.palette.primary.dark,
//                         }}
//                     >
//                         {saveBtnText}
//                     </LoadingButton>
//                 )}
//             </DialogActions>
//         </Dialog>
//     );
// }


import * as React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
// eslint-disable-next-line import/named
import { TransitionProps } from '@mui/material/transitions';

import LoadingButton from './LoadingButton';
import textConst from '../utils/textConst';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type AlertDialogProps = {
  open: boolean;
  title: string;
  content: string;
  saveBtnText?: string;
  customColor?: any;
  handleClose: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleSave: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isLoading?: boolean;
  [key: string]: any;
};

export default function AlertReminderDialog({
  open,
  title,
  content,
  saveBtnText,
  handleClose,
  handleSave,
  isLoading,
  ...rest
}: AlertDialogProps) {
  
  return (
    <Dialog
      {...rest}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          {textConst.cancel}
        </Button>
        {saveBtnText && (
          <LoadingButton
            isLoading={!!isLoading}
            onClick={handleSave}
            variant="outlined"
          >
            {saveBtnText}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

