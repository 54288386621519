import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { chartColors } from '../../utils/constant';
import { Box, Card, CardContent, Dialog, DialogContent, Pagination, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchBarReport from '../SearchBarReport';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';

interface PieChartForCourseProps {
    userCourses: any;
    targetCourseName: string;
    searchVal: any;
    setSearchVal: any;
    searchUserVal: any;
    searchCourseVal: any;
}

const itemsPerPage = 15;

const PieChartForCourseUser: React.FC<PieChartForCourseProps> = ({
    userCourses,
    targetCourseName,
    searchVal,
    setSearchVal,
    searchUserVal,
    searchCourseVal
}) => {

    const navigate = useNavigate();

    const [show, setShow] = useState(false)
    const [showAssignedUsers, setShowAssignedUsers] = useState(false);
    const [userStatus, setUserStatus] = useState('')
    const [userList, setUserList] = useState<any>([])

    const [currentPage, setCurrentPage] = useState(1)
    const [userSearchVal, setUserSearchVal] = useState<string>('');
    const [filteredUserList, setFilteredUserList] = useState<any>(userList);


    // const filteredCourses = userCourses?.courses.filter(
    //     (course: any) =>
    //         searchVal ? course.course.name === targetCourseName &&
    //             course?.course?.name.toLowerCase().includes(searchVal?.toLowerCase()) && !course?.user?.deletedBy && course?.user 
    //             : course.course.name === targetCourseName && !course?.user?.deletedBy && course?.user
    // );

    const filteredCourses = userCourses?.courses.filter((course: any) => {
        const userFullName = course.user?.firstName + ' ' + course.user?.lastName;

        return (searchVal || searchUserVal || searchCourseVal)
            ? (
                course.course.name === targetCourseName &&
                (
                    (searchVal && (
                        course?.course?.name.toLowerCase().includes(searchVal?.toLowerCase()) ||
                        userFullName.toLowerCase().includes(searchVal?.toLowerCase())
                    )) ||
                    (searchCourseVal && (
                        course?.course?.name.toLowerCase().includes(searchCourseVal?.toLowerCase()) ||
                        userFullName.toLowerCase().includes(searchCourseVal?.toLowerCase())
                    ))
                    ||
                    (searchUserVal && userFullName.toLowerCase().includes(searchUserVal?.toLowerCase()))
                ) &&
                !course?.user?.deletedBy &&
                course?.user
            )
            : (
                course.course.name === targetCourseName &&
                !course?.user?.deletedBy &&
                course?.user
            );
    });



    // const filteredCourses = userCourses?.courses.filter(
    //     (course: any) =>
    //         searchVal
    //             ? course.course.name === targetCourseName &&
    //             ((course?.course?.name.toLowerCase().includes(searchVal?.toLowerCase()) ||
    //                 (course.user?.firstName + ' ' + course.user?.lastName)
    //                     .toLowerCase()
    //                     .includes(searchVal.toLowerCase())) &&
    //                 !course?.user?.deletedBy &&
    //                 course?.user)
    //             : course.course.name === targetCourseName && !course?.user?.deletedBy && course?.user
    // );

    // const allStatuses = ['Completed', 'In Progress', 'Not Yet Started', 'Recompletion'];

    // const statusCounts: Record<string, number> = allStatuses.reduce((counts: any, status: string) => {
    //     const count = filteredCourses?.filter((course: any) => course.status === status).length;
    //     counts[status] = count;
    //     return counts;
    // }, {}) || {};

    // ...

    const uniqueStatuses: string[] = Array.from(
        new Set(filteredCourses.map((course: any) => course.status))
    );

    const allStatuses: string[] = uniqueStatuses.length > 0 ? uniqueStatuses : ['No Status'];


    const statusCounts: Record<string, number> = allStatuses.reduce(
        (counts: Record<string, number>, status: string) => {
            const count = filteredCourses?.filter(
                (course: any) => course.status === status
            ).length;
            counts[status] = count || 0;
            return counts;
        },
        {}
    ) || {};


    const totalCount = Object.values(statusCounts).reduce((acc: number, count: number) => acc + count, 0);

    const chartData = {
        datasets: [
            {
                data: Object.values(statusCounts),
                backgroundColor: Object.keys(statusCounts).map(
                    (status) => chartColors[status]
                ),
                hoverBackgroundColor: Object.keys(statusCounts).map(
                    (status) => chartColors[status]
                ),
            },
        ],
    };

    const handleStatusClick = (status: string, userName: string) => {
        setUserStatus(status);
        setUserList(userName);
        setShow(true);
    };

    // const handleAssignUsersClick = () => {
    //     console.log(filteredCourses);

    //     const allAssignedUsers = filteredCourses
    //         .filter((course: any) => course.status !== 'Recompletion')
    //         .map((course: any) => course.user?.firstName && course.user?.firstName + ' ' + course.user?.lastName || 'Deleted User');

    //     setUserStatus('All Users');
    //     setUserList(allAssignedUsers);
    //     setFilteredUserList(allAssignedUsers);
    //     setShowAssignedUsers(true);
    // };

    const handleAssignUsersClick = () => {
        const assignedUsersWithIds = filteredCourses
            // .filter((course: any) => course.status !== 'Recompletion')
            .map((course: any) => {
                const userId = course.user?._id;
                const courseId = course.course?._id;
                const userName = course.user?.firstName && course.user?.firstName + ' ' + course.user?.lastName || 'Deleted User';

                return { userId, courseId, userName };
            });
        setUserStatus('All Users');
        setUserList(assignedUsersWithIds);
        setFilteredUserList(assignedUsersWithIds);
        setShowAssignedUsers(true);
    };


    const handleUserDetailsClick = (user: any) => {
        const userId = user?.userId;
        const courseId = user?.courseId

        navigate(`/users/list/user/${userId}/course/${courseId}`);
    };


    const chartOptions = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context: any) => {
                        const label = context.label || '';
                        const value = context.parsed || 0;
                        return `${label}: ${value}`;
                    },
                },
            },
        },
        onClick: (event: any, elements: any) => {
            if (elements[0]) {
                const clickedStatus = Object.keys(statusCounts)[elements[0].index];

                const clickedUsers = filteredCourses
                    .filter((course: any) => course.status === clickedStatus)
                    // .map((course: any) => course.user?.firstName && course.user?.firstName + ' ' + course.user?.lastName || 'Deleted User');
                    .map((course: any) => {
                        const userId = course.user?._id;
                        const courseId = course.course?._id;
                        const userName = course.user?.firstName && course.user?.firstName + ' ' + course.user?.lastName || 'Deleted User';

                        return { userId, courseId, userName };
                    });
                setUserStatus(clickedStatus)
                setUserList(clickedUsers)

                handleStatusClick(clickedStatus, clickedUsers);
            }
        },
    };

    const totalItems = userList.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);


    const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setCurrentPage(newPage);
        setSearchVal('');
    };


    useEffect(() => {
        const filteredUsers = userList.filter((user: any) =>
            user.userName.toLowerCase().includes(userSearchVal.toLowerCase())
        );
        setFilteredUserList(filteredUsers);
    }, [userSearchVal, userList]);


    const displayedUsers = filteredUserList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    return (
        <>
            {(filteredCourses?.length > 0) && (
                <Card>
                    <CardContent sx={{ padding: 0 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderTopRightRadius: '16px',
                                borderTopLeftRadius: '16px',
                                p: '12px 16px',
                                backgroundColor: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <Typography
                                sx={{
                                    color: (theme) => theme.palette.grey[50],
                                    fontWeight: 500,
                                }}
                                variant="subtitle2"
                            >
                                {targetCourseName}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2, mt: 1 }} onClick={handleAssignUsersClick}>
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    fontWeight: 500,
                                    cursor: 'pointer'
                                }} variant="caption"
                            >
                                Assign Users: {totalCount}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                padding: '15px',
                                display: 'flex',
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                                justifyContent: 'center',
                                alignItems: {
                                    xs: 'flex-start',
                                    sm: 'center',
                                },
                                marginTop: '16px',
                            }}
                        >
                            <Box
                                sx={{
                                    height: '200px',
                                    width: '200px',
                                    cursor: 'pointer',
                                }}
                            >
                                <Doughnut data={chartData} options={chartOptions} />
                            </Box>

                            <Box sx={{ ml: '16px', mt: '16px' }}>
                                {Object.keys(statusCounts).map((status) => (
                                    <Box
                                        key={status}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: '4px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                height: '10px',
                                                width: '10px',
                                                mr: 1,
                                                borderRadius: '2px',
                                                backgroundColor: chartColors[status],
                                            }}
                                        ></Box>
                                        <Typography variant="caption">{status}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            )}

            <Dialog
                open={showAssignedUsers}
                scroll='body'
                onClose={() => {
                    setShowAssignedUsers(false);
                    setUserSearchVal('');
                    setCurrentPage(1);
                }}
                sx={{
                    '& .MuiPaper-root': {
                        overflow: 'visible',
                        width: {
                            xs: '100%',
                            sm: '100%',
                        },
                    },
                }}
            >
                <DialogContent
                    sx={{
                        transition: 'transform 0.15s ease-in-out',
                        p: 0
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderTopRightRadius: '3px',
                            borderTopLeftRadius: '3px',
                            p: '12px 16px',
                            backgroundColor: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Typography
                            sx={{
                                color: (theme) => theme.palette.grey[50],
                                fontWeight: 500,
                            }}
                        >
                            {targetCourseName}
                        </Typography>
                        <CloseIcon sx={{ cursor: 'pointer', color: '#FFFFFF' }} onClick={() => {
                            setShowAssignedUsers(false);
                            setUserSearchVal('');
                            setCurrentPage(1);
                        }} />
                    </Box>
                    <Typography variant='h6' sx={{ fontWeight: 600, fontSize: '1.1rem', m: 2 }}>
                        Assign Users List
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mx: 4 }}>
                        <SearchBarReport
                            placeHolder="Search User"
                            size="small"
                            onKeyUp={(value: string) => {
                                setUserSearchVal?.(value.trim());
                            }}
                            resetSearch={!showAssignedUsers}
                            handleClose={() => setUserSearchVal('')}
                        />
                    </Box>
                    <Box sx={{ pt: 2, pb: 4 }}>
                        {displayedUsers.map((user: any, index: any) => (
                            <Box sx={{ display: 'flex', justifyContent: 'space-around' }} key={index}>
                                <Box sx={{ width: '100%', display: 'flex', ml: 6 }}>
                                    <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '8px' }}>
                                        {user.userName}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mr: 6 }}>
                                    <Tooltip title="User Course Details">
                                        <InfoIcon
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => handleUserDetailsClick(user)}
                                        />
                                    </Tooltip>
                                </Box>
                            </Box>
                        ))}

                        {/* Conditional rendering of Pagination */}
                        {totalPages > 1 && (
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handleChangePage}
                                variant="outlined"
                                shape="rounded"
                                sx={{ mt: 6, display: 'flex', justifyContent: 'center' }}
                            />
                        )}
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog
                open={show}
                scroll='body'
                onClose={() => {
                    setShow(false);
                    setUserSearchVal('');
                    setCurrentPage(1)
                }}
                sx={{
                    '& .MuiPaper-root': {
                        overflow: 'visible',
                        width: {
                            xs: '100%',
                            sm: '100%',
                        },
                    },
                }}
            >
                <DialogContent
                    sx={{
                        transition: 'transform 0.15s ease-in-out',
                        p: 0
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderTopRightRadius: '3px',
                            borderTopLeftRadius: '3px',
                            p: '12px 16px',
                            backgroundColor: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Typography
                            sx={{
                                color: (theme) => theme.palette.grey[50],
                                fontWeight: 500,
                            }}
                        >
                            {targetCourseName}
                        </Typography>
                        <CloseIcon sx={{ cursor: 'pointer', color: '#FFFFFF' }} onClick={() => {
                            setShow(false);
                            setUserSearchVal('');
                            setCurrentPage(1)
                        }} />
                    </Box>
                    <Typography variant='h6' sx={{ fontWeight: 600, fontSize: '1.1rem', m: 2 }}>
                        Course Status : {userStatus}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mx: 4 }}>
                        <SearchBarReport
                            placeHolder="Search User"
                            size="small"
                            onKeyUp={(value: string) => {
                                setUserSearchVal?.(value.trim());
                            }}
                            resetSearch={!show}
                            handleClose={() => setUserSearchVal('')}
                        />
                    </Box>
                    <Box sx={{ pt: 2, pb: 4 }}>
                        {/* {displayedUsers.map((user: any, index: any) => (
                            <Typography
                                key={index}
                                variant="body1"
                                sx={{ textAlign: 'center', marginTop: '8px' }}
                            >
                                {user}
                            </Typography>
                        ))} */}

                        {displayedUsers.map((user: any, index: any) => (
                            <Box sx={{ display: 'flex', justifyContent: 'space-around' }} key={index}>
                                <Box sx={{ width: '100%', display: 'flex', ml: 6 }}>
                                    <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '8px' }}>
                                        {user.userName}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mr: 6 }}>
                                    <Tooltip title="User Course Details">
                                        <InfoIcon
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => handleUserDetailsClick(user)}
                                        />
                                    </Tooltip>
                                </Box>
                            </Box>
                        ))}


                        {/* Conditional rendering of Pagination */}
                        {totalPages > 1 && (
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handleChangePage}
                                variant="outlined"
                                shape="rounded"
                                sx={{ mt: 6, display: 'flex', justifyContent: 'center' }}
                            />
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PieChartForCourseUser;
