import { createTheme } from '@mui/material';

import { LIGHT } from './color';
import { pxToRem, themeTypography } from './typography';

const theme = createTheme({
  palette: LIGHT,
  typography: themeTypography,
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'white',
            backgroundColor: LIGHT.grey[300],
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            'fontSize': pxToRem(14),
            'fontWeight': '500',
            'lineHeight': pxToRem(24),
            'backgroundColor': LIGHT.grey[500],
            'color': '#fff',
            '&:hover': {
              backgroundColor: LIGHT.grey[400],
              color: '#fff',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'inherit' },
          style: {
            'fontSize': pxToRem(14),
            'fontWeight': '500',
            'lineHeight': pxToRem(24),
            'backgroundColor': LIGHT.grey[50],
            'color': LIGHT.grey[500],
            '&:hover': {
              backgroundColor: LIGHT.grey[100],
              color: LIGHT.grey[500],
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            'color': LIGHT.grey[400],
            'borderColor': LIGHT.grey[200],
            '&:hover': {
              borderColor: 'transparent',
              backgroundColor: LIGHT.grey[200],
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            'minWidth': '150px',
            'color': LIGHT.primary.dark,
            'borderColor': LIGHT.primary.dark,
            '&:hover': {
              borderColor: 'transparent',
              color: 'white',
              backgroundColor: LIGHT.primary.main,
            },
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          'textTransform': 'none',
          'borderColor': LIGHT.grey[200],
          'color': LIGHT.grey[400],
          '&.Mui-selected': {
            backgroundColor: LIGHT.grey[500],
            color: LIGHT.grey[100],
          },
          '&.Mui-selected:hover': {
            backgroundColor: LIGHT.grey[400],
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          /* '& .MuiInputLabel-outlined': {
            color: LIGHT.grey[300],
          },
          '& label.Mui-focused': {
            color: LIGHT.grey[400],
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: LIGHT.grey[200],
            },
            '&:hover fieldset': {
              // border: `2px solid ${LIGHT.grey[300]}`,
              borderColor: LIGHT.grey[300],
            },
            '&.Mui-focused fieldset': {
              borderColor: LIGHT.grey[400],
            },
          }, */
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-outlined': {
            color: LIGHT.grey[300],
          },
          '& label.Mui-focused': {
            color: LIGHT.grey[400],
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: LIGHT.grey[200],
            },
            '&:hover fieldset': {
              borderColor: LIGHT.grey[300],
            },
            '&.Mui-focused fieldset': {
              borderColor: LIGHT.grey[400],
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(15),
          fontWeight: '400',
          textTransform: 'none',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&, &.Mui-checked': {
            color: LIGHT.grey[500],
          },
        },
      },
    },
  },
});
export default theme;
