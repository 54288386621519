export const primaryColor = {
  100: '#FDEDEC',
  200: '#FACAC7',
  300: '#F5958E',
  400: '#F27269',
  500: '#EF4F43',
  600: '#EB2B1E',
  700: '#D01E12',
  800: '#93160D',
};

export const LIGHT = {
  primary: {
    main: primaryColor[600],
    light: primaryColor[400],
    dark: primaryColor[700],
  },
  secondary: {
    main: '#808EB3',
    light: '#B6BED3',
    dark: '#3C4764',
  },
  grey: {
    50: '#FFFFFF',
    100: '#EDEFF4',
    200: '#B6BED3',
    300: '#808EB3',
    400: '#526189',
    500: '#3C4764',
    600: '#262D3F',
    700: '#1B202E',
  },
};

export const commonColors = {
  red: '#DC3B31',
  brightRed: '#FF4747',
  orange: '#F07F22',
  green: '#14B55E',
  pink: '#cf35ae',
  purple: '#5B50ED',
  tealGreen: '#00A0AE',
};
