import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface ChartData {
  color: string;
  text: string;
  value: number;
}

interface PieChartProps {
  chartData: ChartData[];
}

const PieChart = (props: PieChartProps) => {
  const { chartData } = props;

  const data = {
    datasets: [
      {
        backgroundColor: chartData?.map((item) => item.color),
        borderColor: chartData?.length <= 1 ? 'transparent' : 'white',
        data: chartData?.map((item) => item.value),
      },
    ],
  };
  

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { sm: 'center' },
      }}
    >
      <Box
        sx={{
          height: '265px',
          width: '265px',
        }}
      >
        <Doughnut data={data} />
      </Box>
      <Box sx={{ mt: { xs: 2, sm: 2 }, ml: { xs: 0, sm: '58px' } }}>
        {chartData?.map((item) => {
          return (
            <Box
              key={item.text}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                mb: '4px',
              }}
            >
              <Box
                sx={{
                  height: '10px',
                  width: '10px',
                  mr: 1,
                  borderRadius: '2px',
                  backgroundColor: item.color,
                }}
              ></Box>
              <Typography variant="caption">{item.text}</Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
export default PieChart;
