import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Loader from '../../components/Loader';
import ProfileContent from '../../components/profile/ProfileContent';
import { useGetUserQuery } from '../../store/apis/user';
import { userSelector } from '../../store/user';
import { notify } from '../../utils/commonFunctions';

const Profile = () => {
  const { userId } = useParams();
  const user = useSelector(userSelector);

  const { data, isLoading, isError, error } = useGetUserQuery(
    userId || user?._id
  );
  

  useEffect(() => {
    if (isError) {
      notify(error?.data?.message, 'error', 1200);
    }
  }, [error?.data?.message, isError]);

  return isLoading ? (
    <Loader />
  ) : (
    <Box>
      <ProfileContent selectedProfile={data} />
    </Box>
  );
};

export default Profile;
